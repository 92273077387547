/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../Popup/index';
import TreeApi from '../../services/server/TreeApi';
import ChangeableImage from '../ChangeableImage';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import AppConstants from '../../submodules/logictry_config/s3_config.json';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import Button from '../Button';
import { validateUrl, validateColor } from '../../utils/regex';
import ColorPicker from '../ColorPicker';
import { TreeJsonToTreeText } from '../../models/tree';
import convertColorToHex from '../../utils/convertcolortohex';
import StatefulTextField from '../StatefulTextField';
import { getTitleDescriptionAndImage } from '../../utils/parseexternalcontent';

const Wrapper = styled.form`
  width: 100%;
  padding: 2rem 3rem;
  ${Constants.MediaMobile} {
    padding: 1rem 2rem;
  }
  max-width: 500px;
  h1 {
    text-align: center;
  }
  label {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
  }
  input:not([type="color"]) {
    width: 100%;
    ${Constants.InputBorderBottom}
    padding: 8px;
  }
  h2 {
    margin-top: 2rem;
    font-size: 1rem;
  }
  code {
    font-size: 0.8rem;
  }
  details {
    margin-top: 2rem;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
const InputFields = styled.div`
  margin: 2rem 0;
  > div {
    height: 60px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export default class CollectionNewAppPopup extends React.PureComponent {
  static propTypes = {
    collection: PropTypes.object,
    onClose: PropTypes.func,
  }
  constructor(props) {
    super(props);
    const { collection } = props;
    this.state = {
      title: '',
      description: '',
      url: '',
      image: '',
      color: collection && collection.color || '',
      error: '',
    }
  }
  addApp = () => {
    const { collection, onClose } = this.props;
    const { title, description, url, image, color } = this.state;
    if (!title) return this.setState({ error: 'Title is missing' });
    // if (!description) return this.setState({ error: 'Description is missing' });
    if (!url) return this.setState({ error: 'URL is missing' });
    if (validateUrl(url)) return this.setState({ error: 'URL is invalid' });
    if (color && validateColor(color)) return this.setState({ error: 'Color is invalid' });
    const hexColor = color && convertColorToHex(color);
    const text = TreeJsonToTreeText({
      children: [
        {
          text: title,
          attributes: ['Title'],
          children: [],
        },
        {
          text: description,
          attributes: ['Description'],
          children: [],
        },
        {
          text: '',
          attributes: hexColor ? ['Root', `BackgroundColor=${hexColor}`] : ['Root'],
          children: [],
        }
      ]
    });
    const tree = {
      type: 'Template',
      owner: UserAccount.account._id,
      userLoggedIn: UserAccount.account._id,
      children: { text },
      customSource: { url },
    };
    if (hexColor || image) tree.background = {};
    if (hexColor) tree.background.color = hexColor;
    if (image) tree.background.image = image;
    return TreeApi.createTree(tree).then((results) => {
      if (!results || !results._id || results.error) {
        return this.setState({ error: 'Something went wrong' });
      }
      collection.apps.unshift(results._id);
      LogicBaseCollectionCache.update(collection);
      return onClose();
    });
  }
  render() {
    const { onClose } = this.props;
    const { error, title, description, url, image, color } = this.state;
    let s3Path;
    let signingUrl;
    if (Company.company && Company.company._id) {
      s3Path = `${Company.company._id}/${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.companyfiles}`;
    } else {
      s3Path = `${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.userfiles}`;
    }
    return (
      <>
        <Popup showCloseButton allowBackgroundClose onClose={onClose}>
          <Wrapper>
            <h1>+ New App</h1>
            <InputFields>
              <div>
                <StatefulTextField
                  value={title || ''}
                  onChange={(title) => this.setState({ title, error: '' })}
                  label="Title"
                  maxLength={50}
                  width={'100%'}
                />
              </div>
              <div>
                <StatefulTextField
                  value={description || ''}
                  onChange={(description) => this.setState({ description, error: '' })}
                  label="Description"
                  maxLength={100}
                  width={'100%'}
                />
              </div>
              <div>
                <StatefulTextField
                  value={color || ''}
                  onChange={(color) => this.setState({ color, error: '' })}
                  label="Background Color"
                  maxLength={20}
                  width={'100%'}
                />
                <div style={{ position: 'relative' }}>
                  <ColorPicker
                    onChange={(color) => this.setState({ color })}
                    color={color && convertColorToHex(color) || Constants.PrimaryColor}
                  />
                </div>
              </div>
              <div>
                <StatefulTextField
                  value={image || ''}
                  onChange={(image) => this.setState({ image, error: '' })}
                  label="Background Image"
                  maxLength={1000}
                  width={'100%'}
                />
                <div style={{ position: 'relative', paddingBottom: 8 }}>
                  <ChangeableImage
                    s3Path={s3Path}
                    signingUrl={signingUrl}
                    hideUploadText
                    backgroundImage={image}
                    style={{
                      width: 80,
                      height: 45,
                      backgroundSize: 'cover',
                      borderRadius: 8,
                      border: '1px dashed',
                    }}
                    onUploadFinish={(fullUrl) => {
                      const image = fullUrl.signedUrl.split('?')[0];
                      this.setState({ image });
                    }}
                  />
                </div>
              </div>
              <div>
                <StatefulTextField
                  value={url || ''}
                  onChange={async (url) => {
                    this.setState({ url, error: '' });
                    const { title, description, image } =  await getTitleDescriptionAndImage(url);
                    const update = {};
                    if (title) update.title = title;
                    if (description) update.description = description;
                    if (image) update.image = image;
                    this.setState(update);
                  }}
                  label="Content URL (Canva, Google, Youtube, PDF, etc)"
                  maxLength={1000}
                  width={'100%'}
                />
                <div style={{ position: 'relative', paddingBottom: 8 }}>
                  <ChangeableImage
                    s3Path={s3Path}
                    signingUrl={signingUrl}
                    hideUploadText
                    style={{
                      width: 40,
                      height: 40,
                      backgroundSize: 'cover',
                      borderRadius: 8,
                      border: '1px dashed'
                    }}
                    onUploadFinish={(fullUrl) => {
                      const url = fullUrl.signedUrl.split('?')[0];
                      this.setState({ url });
                    }}
                  />
                </div>
              </div>
              <p>** Upload a new file or provide a URL for an existing YouTube Video, Airtable Base, Google Spreadsheet, Google Doc, Google Calendar, Canva Presentation, or more.</p>
            </InputFields>
            <ButtonsWrapper>
              <Button onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={this.addApp}>
                Create
              </Button>
            </ButtonsWrapper>
            {error && <div style={{ color: 'red', textAlign: 'center', margin: '0 0 1rem' }}>{error}</div>}
          </Wrapper>
        </Popup>
      </>
    );
  }
}
