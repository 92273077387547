import React from 'react';
import styled from 'styled-components';
// import { PropTypes } from 'prop-types';
import Popup from '../Popup/index';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import Tree from '../../models/tree';
import Button from '../Button/index';
import StatefulTextField from '../StatefulTextField/index';
import ColorPicker from '../ColorPicker';
import UploadFolder from '../UploadFolder';
import AppConstants from '../../submodules/logictry_config/s3_config.json';
import Constants from '../../submodules/logictry_config/constants';
import DatabaseCollection from '../../models/databasecollection';
import BoxPopup from '../../containers/TreePage/BoxPopup';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import Link from '../../models/link';
import ChangeableImage from '../ChangeableImage';
import convertColorToHex from '../../utils/convertcolortohex';
import { validateColor } from '../../utils/regex';
import Asset from '../../models/asset';
import { getTitleDescriptionAndImage } from '../../utils/parseexternalcontent';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  padding: 2rem 3rem;
  ${Constants.MediaMobile} {
    padding: 1rem 2rem;
  }
  width: 500px;
`;
const SettingWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    padding: 6px;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  width: 100%;
`;
const ColorLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  margin: 0.125rem 0;
  > div:last-child {
    flex-shrink: 0;
  }
  input {
    padding: 4px 0;
  }
`;
const UploadLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  margin: 0.125rem 0;
  input {
    padding: 4px 0;
  }
  > div:last-child {
    flex-shrink: 0;
  }
`;

const PrivateText = 'Only users invited can see or edit';
const CompanyViewText = 'Anyone in the company can view';
const LoggedInLinkViewText = 'Only users logged in can view';
const LinkViewText = 'Anyone with the URL can view';
const LinkEditText = 'Anyone with the URL can edit';
const textMapping =  {
  Private: {
    text: PrivateText,
    icon: 'fas fa-eye-slash',
  },
  CompanyView: {
    text: CompanyViewText,
    icon: 'fas fa-building',
  },
  LoggedInLinkView: {
    text: LoggedInLinkViewText,
    icon: 'fas fa-eye',
  },
  LinkView: {
    text: LinkViewText,
    icon: 'fas fa-eye',
  },
  LinkEdit: {
    text: LinkEditText,
    icon: 'fas fa-edit',
  },
};
const AllowText = 'Embed is enabled';
const DenyText = 'Embed is disabled';
const EmbedMapping =  {
  Enabled: {
    text: AllowText,
    icon: 'fas fa-eye',
  },
  Disabled: {
    text: DenyText,
    icon: 'fas fa-eye-slash',
  },
};

export default class SettingsBox extends BoxPopup {
  state = {
    selectSettingsOpen: false,
    embedSettingsOpen: false,
    error: '',
  }
  onViewChange = (setting) => {
    const { domainObject } = this.props;
    domainObject.updateShareSetting(setting);
    this.setState({ selectSettingsOpen: false });
  }
  onEmbedChange = (setting) => {
    const { domainObject } = this.props;
    domainObject.updateEmbedSetting(setting);
    this.setState({ embedSettingsOpen: false });
  }
  onPasswordChange = (password) => {
    const { domainObject } = this.props;
    domainObject.updatePassword(password);
    this.forceUpdate();
  }
  onBackgroundImageChange = (_image) => {
    const { domainObject } = this.props;
    domainObject.updateBackgroundImage(_image);
    this.forceUpdate();
  }
  onBackgroundColorChange = (_color) => {
    const { domainObject } = this.props;
    domainObject.updateBackgroundColor(_color);
    this.setState({ error: '' });
    this.forceUpdate();
  }
  onCustomSourceChange = (_url) => {
    const { domainObject } = this.props;
    domainObject.updateCustomSource(_url);
    getTitleDescriptionAndImage(_url).then(({ image }) => {
      if (image) this.onBackgroundImageChange(image);
    });
    this.forceUpdate();
  }
  onCustomSourceConfigChange = (_config) => {
    const { domainObject } = this.props;
    domainObject.updateCustomSourceConfig(_config);
    this.forceUpdate();
  }
  onLinkChange = (_link) => {
    const { domainObject } = this.props;
    domainObject.updateLink(_link);
    this.forceUpdate();
  }
  onSchemaChange = (_schema) => {
    const { domainObject } = this.props;
    domainObject.updateSchema(_schema);
    this.forceUpdate();
  }
  onIndicesChange = (_schema) => {
    const { domainObject } = this.props;
    domainObject.updateIndices(_schema);
    this.forceUpdate();
  }
  onFunctionsChange = (_schema) => {
    const { domainObject } = this.props;
    domainObject.updateFunctions(_schema);
    this.forceUpdate();
  }
  onBackgroundImageUploadComplete = (url) => {
    const fileUrl = url.signedUrl.split('?')[0];
    const { domainObject } = this.props;
    domainObject.updateBackgroundImage(fileUrl);
    this.forceUpdate();
  }
  onFileUploadComplete = (url) => {
    const fileUrl = url.signedUrl.split('?')[0];
    const { domainObject } = this.props;
    domainObject.updateCustomSource(fileUrl);
    this.forceUpdate();
  }
  onCustomSourceUploadComplete = (url) => {
    const { domainObject } = this.props;
    domainObject.updateCustomSource(url);
    this.forceUpdate();
  }
  render() {
    const { company } = Company;
    const { isDevAccount, account } = UserAccount;
    const { domainObject } = this.props;
    const { selectSettingsOpen, embedSettingsOpen, error } = this.state;
    const { shareSetting, embedSetting, password, background, customSource, customSourceConfigString, schemaString, indicesString, functionsString, link } = domainObject;
    let s3Path;
    let signingUrl;
    if (Company.company && Company.company._id) {
      s3Path = `${Company.company._id}/${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.companyfiles}`;
    } else {
      s3Path = `${UserAccount.account._id}/`;
      signingUrl = `/v1${AppConstants.S3.signingUrls.userfiles}`;
    }
    return (
      <div>
        <Popup
          onClose={this.close}
          allowBackgroundClose
        >
          <Wrapper>
            <h1>Settings</h1>
            <SettingWrapper role="button" onClick={() => this.setState({ selectSettingsOpen: true })} ref={(e) => { if (e) this.anchorEl = e; }}>
              <i className={textMapping[shareSetting].icon} />
              <div>{textMapping[shareSetting].text}</div>
              <i className="fas fa-angle-down" />
            </SettingWrapper>
            {domainObject instanceof Tree && <SettingWrapper role="button" onClick={() => this.setState({ embedSettingsOpen: true })} ref={(e) => { if (e) this.embedEl = e; }}>
              <i className={EmbedMapping[embedSetting].icon} />
              <div>{EmbedMapping[embedSetting].text}</div>
              <i className="fas fa-angle-down" />
            </SettingWrapper>}
            {domainObject instanceof Tree && <StatefulTextField
              value={password || ''}
              onChange={this.onPasswordChange}
              label="Password"
              maxLength={256}
              width={'100%'}
            />}
            <ColorLine>
              <StatefulTextField
                value={background && background.color || ''}
                onChange={this.onBackgroundColorChange}
                label="Background Color"
                maxLength={20}
                width={'100%'}
              />
              <ColorPicker onChange={this.onBackgroundColorChange} color={background && background.color && convertColorToHex(background.color)} />
            </ColorLine>
            {!(domainObject instanceof Asset) && <UploadLine>
              <StatefulTextField
                value={background && background.image || ''}
                onChange={this.onBackgroundImageChange}
                label="Background Image"
                maxLength={500}
                width={'100%'}
              />
              <div style={{ position: 'relative', paddingBottom: 8 }}>
                <ChangeableImage
                  onlyAllowImage
                  s3Path={s3Path}
                  signingUrl={signingUrl}
                  hideUploadText
                  style={{
                    width: 80,
                    height: 45,
                    backgroundSize: 'cover',
                    borderRadius: 8,
                    border: '1px dashed',
                  }}
                  onUploadFinish={this.onBackgroundImageUploadComplete}
                  backgroundImage={background && background.image || ''}
                />
              </div>
            </UploadLine>}
            {domainObject instanceof Tree && <UploadLine>
              <StatefulTextField
                value={customSource && customSource.url || ''}
                onChange={this.onCustomSourceChange}
                label="Content URL (Canva, Google, PDF, etc)"
                maxLength={1000}
                width={'100%'}
              />
              {account && account._id === '5cf13c4899397a33fd191007' && <div style={{ position: 'relative', border: '1px dashed', width: 40, height: 40, borderRadius: 8 }}>
                <UploadFolder
                  onComplete={this.onCustomSourceUploadComplete}
                />
              </div>}
              <div style={{ position: 'relative', paddingBottom: 8 }}>
                <ChangeableImage
                  s3Path={s3Path}
                  signingUrl={signingUrl}
                  hideUploadText
                  style={{
                    width: 40,
                    height: 40,
                    backgroundSize: 'cover',
                    borderRadius: 8,
                    border: '1px dashed',
                  }}
                  onUploadFinish={this.onFileUploadComplete}
                />
              </div>
            </UploadLine>}
            {account && account._id === '5cf13c4899397a33fd191007' && domainObject instanceof Tree && <StatefulTextField
              value={customSourceConfigString || ''}
              onChange={this.onCustomSourceConfigChange}
              label="Custom App (config)"
              maxLength={40000}
              width={'100%'}
            />}
            {domainObject instanceof Link && <StatefulTextField
              value={link || ''}
              onChange={this.onLinkChange}
              label="Redirect to URL"
              maxLength={1000}
              width={'100%'}
            />}
            {isDevAccount && domainObject instanceof DatabaseCollection && <StatefulTextField
              value={schemaString}
              onChange={this.onSchemaChange}
              label="Schema"
              maxLength={4000}
              width={'100%'}
            />}
            {isDevAccount && domainObject instanceof DatabaseCollection && <StatefulTextField
              value={indicesString}
              onChange={this.onIndicesChange}
              label="Indices"
              maxLength={4000}
              width={'100%'}
            />}
            {isDevAccount && domainObject instanceof DatabaseCollection && <StatefulTextField
              value={functionsString}
              onChange={this.onFunctionsChange}
              label="Functions"
              maxLength={4000}
              width={'100%'}
            />}
            <ButtonsWrapper>
              <Button onClick={this.close} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => {
                if (background && background.color && validateColor(background.color)) return this.setState({ error: 'Invalid color' });
                return this.saveAndClose();
              }} color="primary" autoFocus>
                Save
              </Button>
            </ButtonsWrapper>
            {error && <div style={{ color: 'red', textAlign: 'center', margin: '0 0 1rem' }}>{error}</div>}
          </Wrapper>
        </Popup>
        {selectSettingsOpen && <Popup
          allowBackgroundClose
          onClose={() => this.setState({ selectSettingsOpen: false })}
          anchorEl={this.anchorEl}
          anchorBottomRight
        >
          <MenuList>
            <MenuItem onClick={() => this.onViewChange('Private')}>
              <i className="fas fa-eye-slash"></i>
              <div style={{ marginLeft: 10 }}>{PrivateText}</div>
            </MenuItem>
            {company && company._id && <MenuItem onClick={() => this.onViewChange('CompanyView')}>
              <i className="fas fa-building"></i>
              <div style={{ marginLeft: 10 }}>{CompanyViewText}</div>
            </MenuItem>}
            <MenuItem onClick={() => this.onViewChange('LoggedInLinkView')}>
              <i className="fas fa-eye"></i>
              <div style={{ marginLeft: 10 }}>{LoggedInLinkViewText}</div>
            </MenuItem>
            <MenuItem onClick={() => this.onViewChange('LinkView')}>
              <i className="fas fa-eye"></i>
              <div style={{ marginLeft: 10 }}>{LinkViewText}</div>
            </MenuItem>
          </MenuList>
        </Popup>}
        {embedSettingsOpen && <Popup
          allowBackgroundClose
          onClose={() => this.setState({ embedSettingsOpen: false })}
          anchorEl={this.embedEl}
          anchorBottomRight
        >
          <MenuList>
            <MenuItem onClick={() => this.onEmbedChange('Disabled')}>
              <i className="fas fa-eye-slash"></i>
              <div style={{ marginLeft: 10 }}>{DenyText}</div>
            </MenuItem>
            <MenuItem onClick={() => this.onEmbedChange('Enabled')}>
              <i className="fas fa-eye"></i>
              <div style={{ marginLeft: 10 }}>{AllowText}</div>
            </MenuItem>
          </MenuList>
        </Popup>}
      </div>
    );
  }
}
