import React from 'react';
import styled from 'styled-components';
import UserAccount from '../../services/UserAccount';
import UserCache from '../../services/cache/UserCache';
import UserApi from '../../services/server/UserApi';
import Scrollable from '../../components/Scrollable';
import WindowSize from '../../services/WindowSize';
import UserProfileHeader from '../../components/UserProfileHeader';

const Leaderboard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  max-width: 52rem;
  margin: auto;
  h1 {
    margin: 2rem 0;
    text-align: center;
  }
`;

export default class LogicBaseAnalyticsPage extends React.PureComponent {
  state = {
    users: [],
    count: 0
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserApi.query({ page: 1, count: 1000 }).then((users) => { this.setState({ users }); });
    UserApi.getCount().then(({ count }) => { this.setState({ count }); });
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    UserCache.offStateUpdate(this);
  }
  render() {
    const { users, count } = this.state;
    const { mobile } = WindowSize;
    const { isDevAccount } = UserAccount;
    if (!isDevAccount) return null;
    return (
      <Scrollable
        vertical
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '0 1rem 2rem 1rem' : '0 2rem 2rem 2rem' }}
        rememberScrollPosition={`logicbaseanalytics`}
        onPagination={this.onPagination}
      >
        <Leaderboard ref={(e) => { this.appListRef = e; }}>
          <h1>Analytics</h1>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <h2>{count} users</h2>
          </div>
          {users && users.map((user) => (
            <div key={user._id} style={{ marginBottom: '1rem' }}>
              <UserProfileHeader
                user={user}
                small
              />
              <div key={user._id} style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'center' }}>
                <div>{user.originalSubdomain || 'Logictry'}</div>
                <div style={{ whiteSpace: 'nowrap' }}>{new Date(user.createdTime).toLocaleString()}</div>
              </div>
            </div>
          ))}
        </Leaderboard>
      </Scrollable>
    )
  }
}
