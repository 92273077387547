import Service from '../Service';
import Server from './index';

export default class Api extends Service {
  showJson = false;
  async get(id, _query) {
    let url = `/${this.url}/${id}${this.showJson ? '.json' : ''}`;
    if (_query && Object.keys(_query).length > 0) url = `${url}?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`;
    return Server.__getRequest(url, null, id);
  }
  async getCount(_query, _params) {
    let url = `${this.__formatUrl(null, _params)}/count${this.showJson ? '.json' : ''}`;
    if (_query && Object.keys(_query).length > 0) url = `${url}?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`;
    return Server.__getRequest(url);
  }
  async query(_query, _params, _returnFullResponse) {
    return Server.__getRequest(this.__formatUrl(_query, _params), _returnFullResponse);
  }
  async create(_object, _query, _params, _returnFullResponse) {
    return Server.__postRequest(this.__formatUrl(_query, _params), _object, _returnFullResponse);
  }
  async update(_object, _query, _params, _returnFullResponse) {
    return Server.__patchRequest(this.__formatUrl(_query, _params), _object, _returnFullResponse);
  }
  async delete(_params, body, _returnFullResponse) {
    return Server.__deleteRequest(this.__formatUrl(null, _params), { ...(body || {}) }, _returnFullResponse);
  }
  __formatUrl(_query, _params) {
    let queryString = '';
    if (_query) queryString = `?${Object.keys(_query).map((_key) => `${_key}=${_query[_key]}`).join('&')}`;
    if (_params && _params._id) return `/${this.url}/${_params._id}${queryString}`;
    return `/${this.url}${queryString}`;
  }
}
