import UserCache from '../services/cache/UserCache';
import Constants from '../submodules/logictry_config/constants';

export default function getFormattedEditString(text) {
  let formattedText = text;
  const regex = /@([a-fA-F0-9]{24})/g;
  const matches = text.match(regex) || [];
  matches.forEach((m) => {
    const user = (UserCache.getUsersByIds([m.slice(1)]) || [])[0];
    formattedText = formattedText.replace(m, `<span id="${user._id}" style="background: ${Constants.MediumBackground};">${user.fullname || `@${user.username || `${user._id.slice(0, 8)}...`}`}</span>`);
  });
  return formattedText;
}