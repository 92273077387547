/*
 * FeaturePage
 *
 * List all the features
 */
import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import UserProfileHeader from '../../components/UserProfileHeader';
import LogicBaseAppList from '../../components/LogicBaseAppList/index';
import { getUserAppPins, getAccountCollections } from '../../services/Pagination';
import LoadingIndicator from '../../components/LoadingIndicator';
import LogicBaseUserCache from '../../services/cache/LogicBaseUserCache';
import Scrollable from '../../components/Scrollable';
import WindowSize from '../../services/WindowSize';
import LogicBaseCollectionRow from '../../components/LogicBaseCollectionRow';
import Popup from '../../services/Popup';

const Wrapper = styled.div`
  margin: auto;
  padding: 2rem 1rem;
  > div:last-child {
    margin: 2rem auto;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    ${Constants.MediaMobile} {
      gap: 2rem;
    }
    h2 {
      font-size: ${Constants.LargeFontSize};
      ${Constants.MediaMobile} {
        font-size: ${Constants.SemiLargeFontSize};
      }
      font-weight: 500;
    }
  }
`;
export default class LogicBaseUserPage extends React.PureComponent {
  state = {
    username: ((Navigation.currentLocation[1] === 'users' ? Navigation.currentLocation[2] : Navigation.currentLocation[1].slice(1)) || '').toLowerCase(),
    isAccount: UserAccount.isLoggedIn && Navigation.currentLocation[1] === 'account',
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    LogicBaseUserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    if (this.userAppPins) this.userAppPins.offStateUpdate(this);
    LogicBaseUserCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    TreeCache.offStateUpdate(this);
    if (this.collections) this.collections.offStateUpdate(this);
  }
  onPaginationVertical = () => {
    if (this.collections && this.collections.showMore && this.collections.allFound && this.collections.initialized) {
      this.collections.getMore();
    }
  }
  onPaginationPins = () => {
    if (this.userAppPins && this.userAppPins.showMore && this.userAppPins.allFound && this.userAppPins.initialized) {
      this.userAppPins.getMore();
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { username, isAccount } = this.state;
    if (isAccount) {
      this.user = UserAccount.account;
    } else if (username) {
      const users = UserCache.getUsers({ username });
      this.user = users && (users.length === 0 ? { error: 'NotFound' } : users[0]);
    }
    if (!this.user) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (!this.collections) {
      this.collections = getAccountCollections(this.user._id);
      this.collections.onStateUpdate(this);
    }
    if (this.user && !this.user.error && !this.userAppPins) {
      this.userAppPins = getUserAppPins(this.user._id);
      this.userAppPins.onStateUpdate(this);
    }
    if (this.user && this.user.error) return <FullScreenVerticalCenterContent><h1>User Not Found</h1></FullScreenVerticalCenterContent>;
    if (!this.userAppPins || !this.user) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const publicUser = LogicBaseUserCache.get(this.user._id);
    if (!publicUser) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    let userPins = null;
    if (this.userAppPins.currentPage && this.userAppPins.allFound) {
      userPins = TreeCache.getByIds(this.userAppPins.currentPage.map(({ app }) => app));
    }
    return (
      <Scrollable
        vertical={!mobile}
        paginationHeight={400}
        onPagination={this.onPaginationVertical}
        style={{ position: mobile ? 'relative' : 'absolute', inset: 0, padding: mobile ? '1rem 0 2rem' : '0 1rem 2rem' }}
        rememberScrollPosition={`logicbaseuser`}
        onScroll={() => {
          Popup.clearApp();
        }}
      >
        <Wrapper>
          <UserProfileHeader
            user={this.user}
          />
          <div>
            {userPins && userPins.length > 0 && <div>
              <h2>All Pins</h2>
              <Scrollable
                horizontal
                paginationHeight={400}
                onPagination={this.onPaginationPins}
                rememberScrollPosition={`logicbaseuser_pins`}
                onScroll={() => {
                  Popup.clearApp();
                }}
              >
                <LogicBaseAppList
                  apps={userPins}
                  horizontal
                  showHoverInformation
                />
              </Scrollable>
            </div>}
            {this.collections.currentPage.map((collection) => <LogicBaseCollectionRow
              key={collection._id}
              collection={collection}
              showCreateNew={isAccount}
            />)}
          </div>
        </Wrapper>
      </Scrollable>
    );
  }
}
