/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import QuestionAnswersBox from './QuestionAnswersBox';
import Constants from '../../submodules/logictry_config/constants';
import InfoPopup from './InfoPopup';
import GrayOut from '../../services/TreeDisplay';
import CreateChildren from './CreateChildren';
import RenderedHtmlToTextArea from '../../components/RenderedHtmlToTextArea/index';
import { ANSWER, QUESTION } from '../../models/nodetypes';
import RawHtml from '../../components/RawHtml/index';
import TextDivIndent from './TextDivIndent';
import FocusableDiv from './FocusableDiv';
import CreateChild from './CreateChild';
import AddNote from './AddNote';
import TreeCreateNode from '../../services/TreeCreateNode';

const Wrapper = styled.div`
  > div:first-child {
    display: flex;
    align-items: center;
    // position: relative;
    .question-box-hide-till-hover {
      opacity: 0;
    }
    &:hover {
      .question-box-hide-till-hover {
        opacity: 1 !Important;
      }
    }
    > i:first-child {
      cursor: pointer;
    }
  }
`;
const NoMarginWrapper = styled(Wrapper)`
  margin: 0px;
`;
const RequiredText = styled.div`
  margin-left: ${Constants.TreeContentIconIndent};
  color: red;
`;

export default class QuestionBox extends React.PureComponent {
  static propTypes = {
    question: PropTypes.object,
  }
  componentDidMount() {
    const { question } = this.props;
    question.onStateUpdate(this);
    if (question.displayText) question.displayText.onStateUpdate(this);
    GrayOut.onStateUpdate(this);
  }
  componentWillUnmount() {
    const { question } = this.props;
    question.offStateUpdate(this);
    if (question.displayText) question.displayText.offStateUpdate(this);
    GrayOut.offStateUpdate(this);
  }
  getAnswerChildren = (answer) => <CreateChildren key={answer.key} node={answer} />;
  placeholder = QUESTION;
  
  changeValue = (type, value) => {
    const { question } = this.props;
    if (type === 'text') question.updateText(value);
  }
  handleClick = (e, answer) => {
    e.stopPropagation();
    e.preventDefault();
    const { editing } = GrayOut;
    const { question } = this.props;
    GrayOut.addActiveObject(question);
    if (editing) return;
    answer.toggleUserVote();
  }
  questionClicked = () => {
    const { question } = this.props;
    if (!question.multipleAnswers && question.openEnded && question.answers.length === 1) GrayOut.addActiveObject(question.answers[0]);
    else GrayOut.addActiveObject(question);
    setTimeout(() => {
      GrayOut.goToActiveObject();
    });
  }
  continue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { activeObject } = GrayOut;
    if (!activeObject) return;
    const { question } = this.props;
    const { answersChecked } = question;
    if (answersChecked.length === 0) return;

    setTimeout(() => {
      GrayOut.goToNextQuestion();
      this.forceUpdate();
    }, question.updateRate);
  }
  addNewAnswer = () => {
    GrayOut.clearActiveObject();
    setTimeout(() => {
      const { question } = this.props;
      TreeCreateNode.createNode(ANSWER, question, question.parents);
    });
  }
  render() {
    const { question } = this.props;
    const { editing, tree, activeObject } = GrayOut;
    const { root, isDisabled } = tree;
    const { showInlineNotes } = root;
    const { info, displayText, text, formattedProjectText, note, isTextHidden, isNodeHidden, autofill, openEnded, multipleAnswers, showRequiredText, keywords, answersChecked, showChildrenAsSiblings, goToNextOnSelection, placeholderText } = question;
    const marginLeft = Constants.TreeContentIconIndent;
    const showAddNewAnswer = !editing && openEnded && multipleAnswers;
    const isActive = GrayOut.isActive(question) || (activeObject && activeObject.parent === question);

    if (!editing && isNodeHidden) return null;
    
    if (!editing && (!text || isTextHidden)) {
      return (
        <NoMarginWrapper>
          <FocusableDiv node={question}>
            <div style={{ flex: 1 }}>
              <QuestionAnswersBox question={question} />
            </div>
          </FocusableDiv>
          {showRequiredText && <RequiredText>* Required</RequiredText>}
          {(!editing && showChildrenAsSiblings) && answersChecked && <div>{answersChecked.map((answerChecked) => this.getAnswerChildren(answerChecked))}</div>}
        </NoMarginWrapper>
      );
    }

    const attributesHash = question.multipleAnswers ?  'multipleAnswers' : 'singleAnswer';

    return (
      <Wrapper style={{ marginLeft }}>
        <FocusableDiv node={question}>
          <TextDivIndent style={{ flex: editing && 1 }}>
            {editing ? (
              <RenderedHtmlToTextArea node={question} placeholderText={this.placeholder} />
            ) : (
              <RawHtml className="logictry-question__text" html={formattedProjectText} />
            )}
          </TextDivIndent>
          {info && <InfoPopup text={info.text} />}
          <div className="question-box-hide-till-hover">
            {showInlineNotes && !isDisabled && !editing && !note && <AddNote node={question} />}
          </div>
        </FocusableDiv>
        {showRequiredText && <RequiredText>* Required</RequiredText>}
        {note && <CreateChild key={note.key} node={note} />}
        {editing && info && <CreateChild node={info} />}
        {editing && displayText && <CreateChild node={displayText} />}
        {editing && placeholderText && <CreateChild node={placeholderText} />}
        {editing && keywords.map((k) => (
          <CreateChild key={k.key} node={k} />
        ))}
        {editing && autofill && <CreateChild key={autofill.key} node={autofill} />}
        <div key={attributesHash}>
          <QuestionAnswersBox showAddNewAnswer={showAddNewAnswer} question={question} />
        </div>
        {(GrayOut.isActiveOrParentOfActive(question) || (!activeObject && GrayOut.isLastActiveOrParentOfActive(question))) && showAddNewAnswer && <div style={{ lineHeight: '40px', cursor: 'pointer', textDecoration: 'underline', marginLeft: Constants.TreeContentIconIndent, fontSize: Constants.SmallFontSize }} onMouseDown={this.addNewAnswer}>Add Answer</div>}
        {isActive && goToNextOnSelection && multipleAnswers && answersChecked.length > 0 && <div style={{ lineHeight: '40px', cursor: 'pointer', textDecoration: 'underline', fontSize: Constants.SmallFontSize }} onMouseDown={this.continue}>Continue</div>}
        {(!editing && showChildrenAsSiblings) && answersChecked && <div style={{ marginLeft: `-${marginLeft}` }}>{answersChecked.map((answerChecked) => this.getAnswerChildren(answerChecked))}</div>}
      </Wrapper>
    );
  }
}
