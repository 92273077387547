/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserAccount from '../../services/UserAccount';
import Constants from '../../submodules/logictry_config/constants';

const Banner = styled.div`
  background-size: contain;
  width: 100%;
  color: white;
  font-size: 2em;
  border-radius: 1rem;
  h1 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
  }
  div {
    margin: 0;
    font-size: 1rem;
  }
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${Constants.MediaMobileAndTablet} {
    flex-direction: column-reverse;
    h1 {
      font-size: 1.5rem;
    }
    div {
      font-size: 0.875rem;
    }
  }
  > div {
    // flex: 1;
    // height: 100%;
    // width: 100%;
  }
  > div:first-child {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    ${Constants.MediaMobileAndTablet} {
      flex: unset;
      height: unset;
    }
  }
  > div:last-child {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div:first-child {
      display: flex;
      align-items: center;
      margin: 2rem auto;
      ${Constants.MediaMobile} {
        gap: 1rem;
      }
      gap: 2rem;
      > div:first-child {
        > div:first-child {
          font-size: 0.75rem;
          text-transform: uppercase;
        }
        > div:nth-child(2) {
          font-size: 1.25rem;
          font-weight: 500;
          ${Constants.MediaMobile} {
            font-size: 1rem;
          }
        }
      }
      > div:last-child {
        border: 2px solid white;
        border-radius: 2rem;
        padding: 0 1rem;
        line-height: 2.5rem;
        text-align: center;
        font-size: 0.9rem;
        font-weight: 600;
        transition: 0.3s all;
        &:hover {
          background: white;
          color: black;
        }
      }
    }
    > div:last-child {
      filter: brightness(1.5);
      flex: 1;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(2px);
    }
  }
`;

export default class LogicBaseBanner extends React.PureComponent {
  static propTypes = {
    height: PropTypes.string,
    text: PropTypes.string,
  }
  render() {
    const { height, text } = this.props;
    const username = UserAccount.isLoggedIn && (UserAccount.account.fullname && UserAccount.account.fullname.split(' ')[0] || `@${UserAccount.account.username || `${UserAccount.account._id.slice(0, 8)}...`}`) || '';
    const welcome = username && `Welcome ${username}!` || 'Welcome to Logictry';
    return (
      <Banner style={{
        background: 'radial-gradient(at 50% 0%, rgb(255, 255, 255) 0%, rgb(30, 189, 83) 40%, rgb(10, 75, 30) 100%)',
        height: height || 240
      }}>
        <div>
          <h1>{welcome}</h1>
          <div>{text || 'Make better decisions faster with curated expert resources'}</div>
        </div>
        <div></div>
        {/* <div onClick={() => Navigation.site(cta)}>
          {name && <div>
            <div>
              <div>Featured Expert</div>
              <div>{name}</div>
            </div>
            <div>View Book</div>
          </div>}
          <div style={{ backgroundImage: `url(${image})`, width: '100%', maxHeight: 210 }} />
        </div> */}
      </Banner>
    );
  }
}
