import request from 'browser-request';
import SparkMD5 from 'spark-md5';
import Service from '../Service';
import LocalStorage from '../LocalStorage';
import Constants from '../../submodules/logictry_config/constants';
const storage = new LocalStorage('logictry.server');

let _internetDisabled = false;
const _currentRequests = {};
const _rateLimitRequests = {};
let _tokens = {};

const origin = (window.logictry && window.logictry.origin) || window.location.origin;

const API_KEY_TOKEN = 'api-key-token';
const CONTENT_TOKEN_PREFIX = 'content-token-';

class Server extends Service {
  get internetDisabled() {
    return _internetDisabled;
  }
  async checkAuth() {
    return this.__getRequest('/auth');
  }
  registerAccount(body, login) {
    const register = { action: 'createAccount', ...body };
    if (login) register.login = true;
    return this.__postRequest('/auth', register);
  }
  verifyAccount(email, token) {
    return this.__patchRequest('/auth', { action: 'verifyAccount', email, token });
  }
  enableMFA() {
    return this.__patchRequest('/auth', { action: 'enableMFA' });
  }
  verifyMFA(token) {
    return this.__patchRequest('/auth', { action: 'verifyMFA', token });
  }
  disableMFA() {
    return this.__patchRequest('/auth', { action: 'disableMFA' });
  }
  loginAccount(body) {
    return this.__postRequest('/auth', body);
  }
  resetPassword(email) {
    return this.__patchRequest('/auth', { action: 'resetPassword', email });
  }
  logout() {
    if (Constants.isIphone) {
      storage.state = {};
      storage.saveState();
    }
    return this.__patchRequest('/auth', { action: 'logout' });
  }
  updatePassword(password) {
    return this.__patchRequest('/auth', { password });
  }
  getProfile() {
    return this.__getRequest('/users');
  }
  updateProfile(p) {
    return this.__patchRequest('/users', p);
  }
  getCustomerInfo(a) {
    return this.__getRequest(`/users?email=${a}`);
  }
  unsubscribe(email, unsubscribe, token) {
    return this.__patchRequest('/users', { email, unsubscribe, token });
  }
  setDeviceId(device) {
    return this.__patchRequest('/users', { action: 'setDeviceInfo', device });
  }
  getTeams() {
    return this.__getRequest('/teams');
  }
  getTeamById(id) {
    return this.__getRequest(`/teams/${id}`);
  }
  searchBusinesses(query) {
    return this.__getRequest(query);
  }
  getVersion() {
    return this.__getRequest('/version');
  }
  postError(error) {
    return this.__postRequest('/errors', { error });
  }

  async __getRequest(url, _returnFullResponse, _contentTokenId) {
    const response = await this.__request(
      {
        uri: `${origin}/v1${url}`,
        method: 'GET',
        json: true,
      },
      _contentTokenId,
    );
    if (_returnFullResponse) return response;
    return response && response.body;
  }
  async __postRequest(url, body, _returnFullResponse) {
    const response = await this.__request({
      uri: `${origin}/v1${url}`,
      method: 'POST',
      json: true,
      body,
    });
    if (_returnFullResponse) return response;
    return response && response.body;
  }
  async __patchRequest(url, body, _returnFullResponse) {
    const response = await this.__request({
      uri: `${origin}/v1${url}`,
      method: 'PATCH',
      json: true,
      body,
    });
    if (_returnFullResponse) return response;
    return response && response.body;
  }
  async __deleteRequest(url, body, _returnFullResponse) {
    const response = await this.__request({
      uri: `${origin}/v1${url}`,
      method: 'DELETE',
      json: true,
      body: body || {},
    });
    if (_returnFullResponse) return response;
    return response && response.body;
  }
  __request(options, contentTokenId) {
    const requestSignature = SparkMD5.hash(`${options.method} ${options.uri} ${JSON.stringify(options.body)}`);
    if (_currentRequests[requestSignature]) return null;

    // Rate limit in 200 miliseconsecond intervals
    const timeout = setTimeout(() => {
      delete _rateLimitRequests[requestSignature];
    }, 200);
    if (_rateLimitRequests[requestSignature]) {
      const delta = Date.now() - _rateLimitRequests[requestSignature].lastTry;
      clearTimeout(_rateLimitRequests[requestSignature].timeout);
      _rateLimitRequests[requestSignature].timeout = timeout;

      // Allow retry every second, for 3 retries
      if (delta < 1000 || _rateLimitRequests[requestSignature].retryCount > 3) {
        if (_rateLimitRequests[requestSignature].retryCount === 4) {
          const rateLimitError = new Error(
            `Rate Limit Reached: ${options.method} ${options.uri} ${JSON.stringify(options.body)}`,
          );
          setTimeout(() => {
            throw rateLimitError;
          });
        }
        return null;
      }
      _rateLimitRequests[requestSignature].retryCount += 1;
      _rateLimitRequests[requestSignature].lastTry = Date.now();
    } else {
      _rateLimitRequests[requestSignature] = {
        retryCount: 0,
        lastTry: Date.now(),
        timeout,
      };
    }

    let resolve;
    const promise = new Promise((_resolve) => {
      resolve = _resolve;
    });
    _currentRequests[requestSignature] = resolve;

    // Include the csrf token
    // eslint-disable-next-line no-param-reassign
    options.headers = options.headers || {};
    // eslint-disable-next-line no-param-reassign
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    if (csrfToken) options.headers['csrf-token'] = csrfToken.content;
    if (_tokens[API_KEY_TOKEN]) options.headers[API_KEY_TOKEN] = _tokens[API_KEY_TOKEN];
    const contentToken = `${CONTENT_TOKEN_PREFIX}${contentTokenId}`;
    if (_tokens[contentToken]) options.headers[contentToken] = _tokens[contentToken];
    if (Constants.isIphone) {
      options.headers['platform'] = 'ios';
      if (storage.state.accessToken && storage.state.sessionToken) {
        options.headers['access-token'] = storage.state.accessToken;
        options.headers['session-token'] = storage.state.sessionToken;
      }
    }
    // Submit request
    request(options, (e, r) => {
      const currentRequest = _currentRequests[requestSignature];
      if (e) {
        if (e.toString().includes('ERR_INTERNET_DISCONNECTED')) this.stateUpdateCallback(true);
        if (e instanceof SyntaxError) {
          currentRequest({ status: r && r.statusCode });
        } else currentRequest({ body: e, status: r && r.statusCode });
      } else if (r && r.statusCode === 500) {
        currentRequest({ body: { error: 'INTERNAL_SERVER_ERROR' }, status: r && r.statusCode });
      } else if (r && r.statusCode === 401) {
        currentRequest({ body: r.body, status: r && r.statusCode });
        _tokens = {};
        if (Constants.isIphone) {
          storage.state = {};
          storage.saveState();
        }
        this.onLoggedOutStatusCode();
      } else {
        try {
          let body;
          if (r.body.contentTokens) {
            body = r.body.content;
            Object.keys(r.body.contentTokens).forEach((key) => {
              _tokens[key] = r.body.contentTokens[key];
            });
          } else if (r.body.accessToken && r.body.sessionToken) {
            storage.state.accessToken = r.body.accessToken;
            storage.state.sessionToken = r.body.sessionToken;
            storage.saveState();
            delete r.body.accessToken;
            delete r.body.sessionToken;
            body = r.body;
          } else {
            body = r.body;
          }
          this.stateUpdateCallback(false);
          currentRequest({ body, status: r && r.statusCode });
        } catch (f) {
          currentRequest({ body: f, status: r && r.statusCode });
        }
      }
      delete _currentRequests[requestSignature];
    });

    return promise;
  }

  stateUpdateCallback(state) {
    if (state !== _internetDisabled) {
      _internetDisabled = state;
      this.emitStateUpdate();
    }
  }
}

if (Constants.isIphone) {
  // Function to handle online status change
  function handleOnlineStatus() {
    if (navigator.onLine) {
        console.log("You are online!");
    } else {
        console.log("You are offline. Please check your internet connection.");
    }
  }

  // Add event listeners for online and offline events
  window.addEventListener('online', handleOnlineStatus);
  window.addEventListener('offline', handleOnlineStatus);

  // Initial check for online status
  handleOnlineStatus();
}

const singleton = new Server();
export default singleton;
