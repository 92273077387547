import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  > div {
    padding: 0rem 1rem;
    cursor: pointer;
    color: ${Constants.MediumTextGray};
  }
`;

export default function LogicBaseTab({ tabs, selected, onChange }) {
  const selectedStyle = {
    fontWeight: 500,
    color: 'black',
    borderRadius: '0.5rem'
  };
  return <Content>
    {tabs.map(({ name, value }) => (
      <div key={name} style={selected === name && selectedStyle || {}} onClick={() => onChange(name)}>{value}</div>
    ))}
  </Content>
}
