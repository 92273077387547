/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { getCollectionsSubscribed } from '../../services/Pagination';
import WindowSize from '../../services/WindowSize';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import LogicBaseAppLists from '../../components/LogicBaseAppLists';
import Scrollable from '../../components/Scrollable';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import LogicBaseCollectionRow from '../../components/LogicBaseCollectionRow';
import Constants from '../../submodules/logictry_config/constants';
import Button from '../../components/Button';
import LogicBaseBanner from '../../components/LogicBaseBanner';
import Popup from '../../services/Popup';

const Wrapper = styled.div`
  height: 100%;
  inset: 0;
  overflow: hidden;
  position: absolute;
`;
const Content = styled.div`
  inset: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  ${Constants.MediaMobile} {
    gap: 2rem;
  }
  padding-bottom: 4rem;
  min-height: 100%;
`;
const OnlyContentYouSubcribe = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  flex: 1;
  > div {
    box-shadow: ${Constants.CTAShadow};
    padding: 2rem;
    border-radius: 1rem;
    max-width: 420px;
  }
`;
export default class LogicBasePage extends React.PureComponent {
  state = {
    q: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '',
  }
  componentDidMount() {
    LogicBaseCollectionCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
  }
  componentWillUnmount() {
    LogicBaseCollectionCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    if (this.subscriptions) this.subscriptions.offStateUpdate(this);
    this.subscriptions = null;
  }
  onPagination = () => {
    if (this.subscriptions && this.subscriptions.showMore && this.subscriptions.allFound && this.subscriptions.initialized) {
      this.subscriptions.getMore();
      this.forceUpdate();
    }
  }
  render() {
    const { mobile } = WindowSize;
    const { q } = this.state;
    if (q) {
      const showTopics = (!q || q === 'topic');
      if (mobile) return <>
        <div style={{ top: (q || mobile) ? 0 : '3rem' }}>
          <LogicBaseAppLists />
        </div>
      </>;
      return (
        <Wrapper>
          <Content>
            <div style={{ top: (!showTopics || mobile) ? 0 : '3rem' }}>
              <LogicBaseAppLists />
            </div>
          </Content>
        </Wrapper>
      );
    }
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    let collectionsToShow;
    if (UserAccount.isLoggedIn) {
      if (!this.subscriptions) {
        this.subscriptions = getCollectionsSubscribed(UserAccount.account._id);
        this.subscriptions.onStateUpdate(this);
      }
      const subscriptions = this.subscriptions.currentPage;
      if (!subscriptions || (subscriptions.length === 0 && !this.subscriptions.allFound)) return loading;
      const subscribedCollections = LogicBaseCollectionCache.getByIds(subscriptions.map(({ collection }) => collection)) || this.subscribedCollections || [];
      this.subscribedCollections = subscribedCollections;
      collectionsToShow = Array.from(new Set(subscribedCollections.sort((a, b) => new Date(b.updateTime) - new Date(a.updateTime))));
    }
    return (
      <Scrollable
        vertical
        style={{ position: mobile ? 'relative' : 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: mobile ? '1rem 1rem 2rem 1rem' : '0 2rem 2rem 2rem' }}
        paginationHeight={400}
        onPagination={this.onPagination}
        rememberScrollPosition={`logicbasepage`}
        onScroll={() => {
          Popup.clearApp();
        }}
      >
        <Content>
          <LogicBaseBanner />
          {collectionsToShow && collectionsToShow.length > 0 && <>
            {collectionsToShow.map((collection) => <LogicBaseCollectionRow
              key={collection._id}
              collection={collection}
            />)}
            <div style={{ height: 20 }}></div>
          </>}
          <OnlyContentYouSubcribe>
            <div>
              <h1>Browse Content Collections</h1>
              <p>We only show you content collections <b>you subscribe to</b> and nothing more!</p>
              <br></br>
              <Button onClick={() => {
                Navigation.push(Navigation.collections)
              }} filledColor>
                <span>Click to Discover</span>
              </Button>
            </div>
          </OnlyContentYouSubcribe>
        </Content>
      </Scrollable>
    );
  }
}
