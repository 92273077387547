import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';

export default styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${Constants.MediumBackground};
  }
`;
