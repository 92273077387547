import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LoadingIndicator from '../../components/LoadingIndicator';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import WindowSize from '../../services/WindowSize';
import Company from '../../services/Company';
import Storage from '../../services/Storage';
import Navigation from '../../services/Navigation';
import { getTeamProjectsOpen, getTeamSpreadsheetsOpen, getTeamTemplatesOpen, getTeamAssetsOpen, getTeamDatabasesOpen } from '../../services/Pagination';
import ProjectTreesBox from '../TreePage/ProjectTreesBox';
import TemplateTreesBox from '../TreePage/TemplateTreesBox';
import SpreadsheetsBox from '../TreePage/SpreadsheetsBox';
import SelectMenu from '../../components/SelectMenu/index';
import ListViewGridViewSelect from '../TreePage/ListViewGridViewSelect';
import HeaderSection from '../TreePage/HeaderSection';
import AssetsBox from '../TreePage/AssetsBox';
import DatabasesBox from '../TreePage/DatabasesBox';
import Scrollable from '../../components/Scrollable/index';
import LogicBaseBanner from '../../components/LogicBaseBanner';
import CreateTeamPopup from '../../components/CreateTeamPopup';
import UsersBox from '../../components/UsersBox';

const QuickLinks = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  > div {
    width: 260px;
    padding: 1rem 1.5rem;
    background: white;
    border-radius: 0.5rem;
    cursor: pointer;
    border: ${Constants.LightBorder};
    :hover {
      box-shadow: ${Constants.CardShadow};
    }
    > div:first-child {
      font-weight: 600;
    }
  }
`;

const teamPageSelectOptions = [{ name: 'Apps', value: 'apps' }, { name: 'Assets', value: 'assets' }, { name: 'Spreadsheets', value: 'spreadsheets' }, { name: 'Submissions', value: 'submissions' }];

export default class TeamPage extends React.PureComponent {
  state = {
    showEditTeam: false,
    showEditTeamUsers: false,
  }
  componentDidMount() {
    Company.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    Company.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    this.removeStateUpdates();
  }
  getBoxes = (team) => {
    const teamPageSelectType = Navigation.currentSearch.content || 'apps';
    if (teamPageSelectType === 'apps') return <TemplateTreesBox key={team} paginationService={this.templates} team={team} />;
    if (teamPageSelectType === 'assets') return <AssetsBox key={team} paginationService={this.assets} team={team} />;
    if (teamPageSelectType === 'databases') return <DatabasesBox key={team} paginationService={this.databases} team={team} />;
    if (teamPageSelectType === 'spreadsheets') return <SpreadsheetsBox key={team} paginationService={this.spreadsheets} team={team} />;
    if (teamPageSelectType === 'submissions') return <ProjectTreesBox key={team} paginationService={this.projects} team={team} />;
    return null;
  }
  updateState = (_selectType) => {
    Storage.setContentView(_selectType);
    Navigation.push(`${window.location.pathname}${_selectType === 'apps' ? '' : `?content=${_selectType}`}`);
    this.forceUpdate();
  }
  removeStateUpdates = () => {
    if (this.templates) {
      this.templates.offStateUpdate(this);
      this.assets.offStateUpdate(this);
      this.databases.offStateUpdate(this);
      this.spreadsheets.offStateUpdate(this);
      this.projects.offStateUpdate(this);
    }
  }
  render() {
    const { showEditTeam, showEditTeamUsers } = this.state;
    const { mobile } = WindowSize;
    const { companyTeams } = Company;
    if (companyTeams) {
      const team = companyTeams.find((_team) => Navigation.currentLocation[2] === _team._id);
      if (team && (!this.team || this.team._id !== team._id)) {
        this.team = team;
        if (team && team._id) {
          this.removeStateUpdates();
          this.projects = getTeamProjectsOpen(team._id);
          this.spreadsheets = getTeamSpreadsheetsOpen(team._id);
          this.assets = getTeamAssetsOpen(team._id);
          this.templates = getTeamTemplatesOpen(team._id);
          this.databases = getTeamDatabasesOpen(team._id);
          this.templates.onStateUpdate(this);
          this.assets.onStateUpdate(this);
          this.databases.onStateUpdate(this);
          this.spreadsheets.onStateUpdate(this);
          this.projects.onStateUpdate(this);
        }
      }
    }
    if (Company.loginError) return <FullScreenVerticalCenterContent><h1>User Not Authorized</h1></FullScreenVerticalCenterContent>;
    if (!Company.subscriptionVerified) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (!this.team) return <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (this.team.error) return <FullScreenVerticalCenterContent><h1>Team not found</h1></FullScreenVerticalCenterContent>;
    const teamPageSelectType = Navigation.currentSearch.content || 'apps';
    return (
      <Scrollable
        vertical
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, padding: '0 2rem' }}
      >
        <FullScreenVerticalCenterContent key={this.team._id} alignTop>
          {this.team && <DrawerBreadcrumb></DrawerBreadcrumb>}
          <LogicBaseBanner text={this.team.longname || this.team.shortname} />
          {!mobile && <QuickLinks>
            <div onClick={() => this.setState({ showEditTeam: true })}>
              <div>Edit Team</div>
              <div>Modify title, description, and privacy settings.</div>
            </div>
            <div onClick={() => this.setState({ showEditTeamUsers: true })}>
              <div>Edit Team Users</div>
              <div>Add or remove admins and viewers.</div>
              <div></div>
            </div>
          </QuickLinks>}
          {teamPageSelectType && (
            <div style={{ width: '100%' }}>
              <HeaderSection>
                <SelectMenu value={teamPageSelectType} onChange={this.updateState} options={teamPageSelectOptions} />
                <ListViewGridViewSelect />
              </HeaderSection>
              {this.projects && this.getBoxes(this.team._id)}
            </div>
          )}
          {showEditTeam && <CreateTeamPopup open={this.team} onClose={() => this.setState({ showEditTeam: false })} />}
          {showEditTeamUsers && <UsersBox domainObject={this.team} onClose={() => this.setState({ showEditTeamUsers: false })} />}
        </FullScreenVerticalCenterContent>
      </Scrollable>
    );
  }
}
