/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
import Constants from '../../submodules/logictry_config/constants';
import SidebarService from '../../services/SidebarService';
import Navigation from '../../services/Navigation';
import GrayOut from '../../services/TreeDisplay';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import AutoSaveTree from '../../services/AutoSaveTree';
import WindowSize from '../../services/WindowSize';
import Connect from '../../services/Connect';
import TreeCache from '../../services/cache/TreeCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import TreeAnswerBox from './TreeAnswerBox';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import SaveTreeAndSubtrees from '../../utils/TreeParsers/SaveTreeAndSubtrees';
import Sidebar from './Sidebar';
import TextView from './TextView';
import LoggedOutSavePopup from './LoggedOutSavePopup';
import ClickableIcon from '../../components/ClickableIcon/index';
import { findForceIframeContent } from '../../models/nodetypes';
import CreateProject from '../../services/CreateProject';
import { PropTypes } from 'prop-types';
import StatefulTextField from '../../components/StatefulTextField/index';
import StatefulButton from '../../components/StatefulButton/index';
import ChatInterface from '../../components/ObjectSettings/ChatInterface';
import { validateEmail } from '../../utils/regex';
import { loadFont } from '../../utils/fonts';
import EmbedFullscreenButton from '../../components/EmbedFullscreenButton';

const Wrapper = styled.div`
  h1 {
    font-size: ${props => props.fontSizeH1};
  }
  h2 {
    font-size: ${props => props.fontSizeH2};
  }
  h3 {
    font-size: ${props => props.fontSizeH3};
  }
`;
const ContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const EditingDisabled = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  color: white;
  background-color: rgba(0,0,0,0.08);
  > div {
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 0px;
    background-color: ${Constants.PrimaryColor};
    position: absolute;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 2rem;
    line-height: 2rem;
    > div {
      color: white;
      font-weight: bold;
      font-size: ${Constants.SemiSmallFontSize};
    }
  }
`;
const FullscreenWrapper = styled.div`
  position: fixed;
  top: 8px;
  z-index: 4;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;

const originalUrl = window.location.href.replace(window.location.origin, '');
let backgroundColor;
let backgroundColorTimeout;

export default class TreeAnswerPage extends React.PureComponent {
  static propTypes = {
    fullscreen: PropTypes.bool,
    showAppId: PropTypes.string,
    onScroll: PropTypes.func,
  }
  constructor(props) {
    super(props);
    SidebarService.updateSelectType(Navigation.currentSearch.showchatbot === 'true' ? 'Chat' : 'Tree');
    Connect.__iframe = document.createElement('iframe');
    Connect.__iframe.style.position = 'absolute';
    Connect.__iframe.style.inset = '0';
    Connect.__iframe.style.width = '100%';
    Connect.__iframe.style.height = '100%';
    Connect.__iframe.style.border = '0px';
  }
  state = {
    autocreating: false,
    submitEmail: false,
    skipToReport: false,
    loading: true,
    password: '',
    chatbuttonShowContent: false,
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    Connect.onStateUpdate(this);
    SidebarService.onStateUpdate(this.updateState);
    TreeCache.onStateUpdate(this.treeCacheUpdated);
    UserAccount.onStateUpdate(this.treeCacheUpdated);
    this.treeCacheUpdated();
    setTimeout(() => {
      clearTimeout(backgroundColorTimeout);
    }, 20);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    Connect.offStateUpdate(this);
    SidebarService.offStateUpdate(this.updateState);
    TreeCache.offStateUpdate(this.treeCacheUpdated);
    UserAccount.offStateUpdate(this.treeCacheUpdated);
    const { tree } = this.state;
    if (tree && tree._id) {
      tree.offStateUpdate(this.treeUpdated);
    }
    backgroundColorTimeout = setTimeout(() => { backgroundColor = null; }, 500);
  }
  handleClick = () => {
    const { isWidget } = Constants;
    if (!isWidget) return;
    if (Navigation.currentSearch.onclickshowfullscreen !== 'true') return;
    if (Connect.isFullscreen) return;
    Connect.toggleFullscreen();
  }
  get treeIdToGet() {
    const { showAppId } = this.props;
    if (showAppId) return showAppId;
    if (Navigation.currentLocation[1] === 'apps' && Navigation.currentLocation[2]) return Navigation.currentLocation[2];
    return null;
  }
  treeCacheUpdated = () => {
    const { isLoggedIn, account } = UserAccount;
    const { isWidget, apikey } = Constants;
    const { currentSearch } = Navigation;
    const { password, pwd } = currentSearch;
    let { tree } = this.state;
    const { treeIdToGet } = this;
    if (tree && tree._id) tree.offStateUpdate(this.treeUpdated);
    if (!treeIdToGet || treeIdToGet.startsWith('http')) return this.forceUpdate();
    tree = TreeCache.getTree(treeIdToGet, null, null, null, password || pwd, isWidget && apikey);
    if (!tree || tree.error) return this.setState({ tree, autocreating: false });
    if (tree.project && this.state.tree !== tree) {
      this.state.loading = true;
      if (Object.hasOwnProperty.call(currentSearch, 'autocreate')) {
        Navigation.getQueryParameter('autocreate');
      } else if (tree.progress && tree.progress.report || !(isLoggedIn && account._id === tree.owner)) {
        this.state.skipToReport = true;
      }
    } else if (tree.template && this.state.tree !== tree) {
      this.state.loading = true;
      CreateProject.initialize();
    }
    this.state.tree = tree;
    GrayOut.setTree(tree);
    tree.onStateUpdate(this.treeUpdated);

    if (tree.root && tree.root.fontFamily) {
      loadFont(tree.root.fontFamily)
    }

    // Check if autocreate exists
    if (tree.template && Object.hasOwnProperty.call(currentSearch, 'autocreate') && !this.state.autocreating) {
      this.state.autocreating = true;
      this.saveChanges();
      return this.forceUpdate();
    }

    this.state.autocreating = false;
    this.treeUpdated();
    return this.forceUpdate();
  }
  saveProgress = () => {
    SidebarService.updateSelectType('Email', true);
  }
  saveChanges = (email) => {
    const { selectType } = SidebarService;
    const { team } = Navigation.currentSearch;
    const { tree, autocreating } = this.state;
    const { isSavingProjectsAllowed } = tree;
    if (team) tree.addTeam(team);
    if (tree.type === 'Project') {
      SaveTreeAndSubtrees(tree);
    } else if (!isSavingProjectsAllowed) {
      SidebarService.updateSelectType('Text');
    } else if (UserAccount.isLoggedIn) {
      if (!autocreating && selectType === 'Text') tree.reportHasBeenViewed();
      CreateProject.createProject(tree, team);
    } else if (email && !validateEmail(email)) {
      if (!autocreating && selectType === 'Text') tree.reportHasBeenViewed();
      CreateProject.registerAndCreateProject(tree, email, team);
    } else {
      this.setState({ submitEmail: true });
    }
  }
  updateState = () => {
    const { isLoggedIn } = UserAccount;
    const { selectType, saveOnShowReport } = SidebarService;
    const { skipToReport, loading } = this.state;
    this.state.selectType = selectType;
    Connect.sendIframeMessage({
      selectType,
    });
    const { tree } = GrayOut;
    if (!tree) return null;
    const { project } = tree;
    if (project && !loading && isLoggedIn) {
      if (selectType === 'Tree') tree.clearReportHasBeenViewed();
      else if (selectType === 'Text') tree.reportHasBeenViewed();
    }
    if (skipToReport && selectType === 'Text') {
      this.state.skipToReport = false;
    }
    if (selectType === 'Email' && saveOnShowReport) {
      if (isLoggedIn) {
        SidebarService.updateSelectType('Tree', true);
        this.saveChanges();
      } else {
        const email = tree.getReportSaveOnSubmitEmail();
        return this.saveChanges(email);
      }
    }
    if (selectType === 'Text' && saveOnShowReport) {
      const email = tree.getReportSaveOnSubmitEmail();
      return this.saveChanges(email);
    }
    return this.forceUpdate();
  }
  treeUpdated = () => {
    const { tree, loading, skipToReport } = this.state;
    if (!tree) return;
    AutoSaveTree.autoSaveProject(tree);

    if (loading) {
      if (tree.isBusy) {
        clearTimeout(this.waitTillLoadedTimeout);
        this.waitTillLoadedTimeout = null;
      } else if (!this.waitTillLoadedTimeout) {
        this.waitTillLoadedTimeout = setTimeout(() => {
          if (this.state.skipToReport) SidebarService.updateSelectType('Text');
          this.setState({ loading: false });
        }, skipToReport ? 1000 : 200);
      }
    }

    // Check for query parameters
    if (this.__checkQueryParametersTimeout) clearTimeout(this.__checkQueryParametersTimeout);
    this.__checkQueryParametersTimeout = setTimeout(() => {
      this.__checkQueryParametersTimeout = null;
      this.__checkQueryParameters();
    }, 100);
  }
  __checkQueryParameters = () => {
    const { tree } = this.state;
    if (!tree) return;
    const queryString = Navigation.currentSearch;
    const foundParameters = tree.updateQueryParameters(queryString);
    Array.from(foundParameters).forEach((p) => Navigation.getQueryParameter(p));
  }
  toggleFullscreen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Connect.toggleFullscreen();
  }
  onRef = (e) => {
    if (!e || !Connect.__iframe) return;
    const { tree } = this.state;
    const { customSource } = tree;
    const customUrl = customSource && customSource.url;
    let finalUrl;
    let height = '100%';
    if (customUrl) {
      const isImage = isImageUrl(customUrl);
      if (isImage) {
        const image = document.createElement('div');
        image.style.position = 'absolute';
        image.style.inset = '0';
        image.style.width = '100%';
        image.style.backgroundSize = 'contain';
        image.style.backgroundRepeat = 'no-repeat';
        image.style.backgroundPosition = 'center';
        image.style.backgroundImage = `url(${customUrl})`;
        e.appendChild(image);
        return;
      }
      const googleUrl = convertToGooglePreviewOrMobileBasicUrl(customUrl);
      const youtubeUrl = convertToEmbedUrl(customUrl);
      const airtableUrl = validateAirtableUrl(customUrl);
      const googleMapsUrl = convertToGoogleMapsEmbed(customUrl);
      const canvaUrl = convertToCanvaEmbedUrl(customUrl);
      const googleCalendarUrl = convertToGoogleCalendarEmbedUrl(customUrl);
      const vimeoUrl = convertVimeoToEmbed(customUrl);
      const instagramEmbedCode = convertInstagramReelToEmbedUrl(customUrl);
      const tiktokEmbedCode = convertTikTokToEmbedUrl(customUrl);
      const isPDF = isPdfUrl(customUrl);

      // Change URL
      if (isPDF) finalUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${customUrl}`;
      else if (airtableUrl === false) finalUrl = customUrl;
      else finalUrl = googleUrl || youtubeUrl || googleMapsUrl || canvaUrl || googleCalendarUrl || vimeoUrl || instagramEmbedCode || tiktokEmbedCode || customUrl;

      // Handle special height if google spreadsheet to remove tabs
      if (googleUrl && googleUrl.includes("/spreadsheets/")) height = 'calc(100% + 26px)';

      // Add background color if Vimeo Video
      if (vimeoUrl) Connect.__iframe.style.backgroundColor = 'black';
      else Connect.__iframe.style.backgroundColor = null;

      if (googleUrl && googleUrl.includes('/pub?embedded=true')) {
        Connect.__iframe.style.maxWidth = '832px';
        Connect.__iframe.style.margin = 'auto';
      }
      if (instagramEmbedCode || tiktokEmbedCode) {
        Connect.__iframe.style.maxWidth = '600px';
        Connect.__iframe.style.margin = 'auto';
      }
    } else {
      finalUrl = `${window.location.origin}/apps/${tree._id}${window.location.search}`;
    }
    Connect.__iframe.style.height = height;
    Connect.__iframe.src = finalUrl;
    e.appendChild(Connect.__iframe);
  }
  loggedOutSavePopupClosed = (success) => {
    const { saveOnShowReport } = SidebarService;
    const { tree, submitEmail } = this.state;
    const { isSavingProjectsAllowed } = tree;
    const goBackToForm = isSavingProjectsAllowed && saveOnShowReport && submitEmail;
    if (!success && goBackToForm) SidebarService.updateSelectType('Tree');
    return this.setState({ submitEmail: false });
  }
  checkpassword = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('password', this.state.password);
    window.location = url.href;
  }
  successOK = () => this.setState({ submitEmail: false });
  render() {
    const { fullscreen, onScroll } = this.props;
    const { isApp, isWidget, isWidgetOnSameDomain } = Constants;
    const { isAuthorizedToLogIn } = Company;
    const { isLoggedIn } = UserAccount;
    const { mobile } = WindowSize;
    const { selectType, saveOnShowReport } = SidebarService;
    const { autocreating, tree, submitEmail, loading, chatbuttonShowContent } = this.state;
    if (!Constants.isLogicWiki && !this.treeIdToGet) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget}><h1>App Not Found</h1></FullScreenVerticalCenterContent>;
    if (!tree || tree.loading) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={backgroundColor || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (tree.error) {
      if (tree.error === 'PasswordIncorrect') return (
        <FullScreenVerticalCenterContent>
          <h1 style={{ marginBottom: '2rem' }}>Enter Password</h1>
          <StatefulTextField
            value={this.state.password}
            onChange={(password) => { this.setState({ password }); }}
            type="password"
            style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
            label={'Password'}
            onKeyDown={(e) => { if (e.key === 'Enter') { this.checkpassword(); } }}
          />
          <div style={{ margin: 40 }}>
            <StatefulButton
              onClick={this.checkpassword}
              text={'Submit'}
              warning={this.state.warning}
              state=""
            />
          </div>
        </FullScreenVerticalCenterContent>
      );
      return <FullScreenVerticalCenterContent><h1>App Not Found</h1></FullScreenVerticalCenterContent>;
    }

    if (isWidget && !Connect.isFullscreen && Navigation.currentSearch.showplaceholder === 'true') {
      return <div style={{ cursor: 'pointer' }} onClick={this.toggleFullscreen}>
        <img alt={'placeholder'} style={{ position: 'fixed', inset: 0, width: '100%', height: '100%', objectFit: 'contain' }} src={getPlaceholderImage()}></img>
      </div>
    }

    if (!!autocreating) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={backgroundColor || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const { isOwnerOrAdmin, isLocked, project, isCustomApp, isSavingProjectsAllowed } = tree;
    const showSidebar = isLoggedIn && isApp && !mobile && !fullscreen && isAuthorizedToLogIn;
    const right = showSidebar ? 40 : 0;
    const runInIframe = isCustomApp || (!isWidget && (Navigation.currentSearch.iframe || findForceIframeContent(tree.root)));
    const editingVersionOfTree = TreeCache.getTree(tree._id, true);
    if (!editingVersionOfTree) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={backgroundColor || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const dontShowReportUntilSaved = isSavingProjectsAllowed && saveOnShowReport;
    const dontShowEmailUntilSaved = dontShowReportUntilSaved && submitEmail;
    const showRootProperty = (selectType === 'Tree' || (selectType === 'Text' && dontShowReportUntilSaved)) && tree.root;
    const showReportProperty = selectType === 'Text' && tree.report;
    backgroundColor = tree.customSource && tree.customSource.url && tree.background && tree.background.color
       || showRootProperty && tree.root.backgroundColor || showReportProperty && tree.report.backgroundColor;
    const backgroundImage = showRootProperty && tree.root.backgroundImage || showReportProperty && tree.report.backgroundImage;
    const backgroundVideo = showRootProperty && tree.root.backgroundVideo || showReportProperty && tree.report.backgroundVideo;
    const className = showRootProperty && 'logictry-root' || showReportProperty && 'logictry-report';
    const showYouAreNotAuthorizedToEdit = isLoggedIn && project && !isOwnerOrAdmin && selectType === 'Tree';
    const showLoginToEditYourSubmission = project && !isLoggedIn && selectType === 'Tree';
    const showProjectLocked = isLoggedIn && project && isOwnerOrAdmin && isLocked;
    const showDeleted = tree.isDeleted;
    const showLoginToView = !isLoggedIn && tree.shareSetting === 'LoggedInLinkView';
    const filter = showLoginToView && 'blur(6px) brightness(0.5)';
    const fontFamily = showRootProperty && tree.root.fontFamily || showReportProperty && tree.report.fontFamily;
    const fontSizeH1 = showRootProperty && tree.root.fontSizeH1 || showReportProperty && tree.report.fontSizeH1;
    const fontSizeH2 = showRootProperty && tree.root.fontSizeH2 || showReportProperty && tree.report.fontSizeH2;
    const fontSizeH3 = showRootProperty && tree.root.fontSizeH3 || showReportProperty && tree.report.fontSizeH3;
    const showButton = isWidget && !Connect.isFullscreen && Navigation.currentSearch.showbutton === 'true';
    const showToggle = isWidget && Navigation.currentSearch.showtoggle === 'true';
    const hideContentForChatbutton = showButton && !chatbuttonShowContent;
    const contentStyle = {
      filter,
      right: showButton && 8 || right,
      top: showButton && 8 || null,
      bottom: showButton && 100 || null,
      left: showButton && 8 || null,
      boxShadow: showButton && 'rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px' || null
    };
    const showChatbot = selectType === 'Chat';
    if (showButton) Connect.setButtonExpanded(chatbuttonShowContent);
    return (
      <>
        {showButton && <div
          onClick={() => this.setState({ chatbuttonShowContent: !chatbuttonShowContent })}
          style={{ cursor: 'pointer', width: 60, height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', background: Constants.PrimaryColor, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px', position: 'fixed', bottom: 32, right: 4 }}
        >
          <img alt="logictry" style={{ width: 32 }} src="https://logictrystatic.s3.us-east-1.amazonaws.com/images/icon_sharp_white.svg"></img>
        </div>}
        {!hideContentForChatbutton && <Wrapper
          onClick={this.handleClick}
          fontSizeH1={fontSizeH1}
          fontSizeH2={fontSizeH2}
          fontSizeH3={fontSizeH3}
          style={{ opacity: loading ? 0 : 1, fontFamily }}
          key={`${tree.key}`}
        >
          <DrawerBreadcrumb key={tree.key}></DrawerBreadcrumb>
          {showChatbot && (
            <ChatInterface tree={tree} style={{ ...contentStyle }} />
          ) || runInIframe && (
            <ContentWrapper
              style={{
                ...contentStyle,
                backgroundColor,
                backgroundImage: backgroundImage && `url(${backgroundImage})`
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  inset: backgroundColor ? '1rem' : '0rem',
                  borderRadius: backgroundColor ? '0.5rem' : '0rem',
                  overflow: 'hidden'
                }}
                ref={this.onRef}
              ></div>
            </ContentWrapper>
          ) || (<>
            <ContentWrapper style={{ ...contentStyle }} className={className}>
              {backgroundColor && <div style={{ position: 'absolute', inset: 0, backgroundColor }}></div>}
              {backgroundImage && <ContentWrapper style={{ position: 'absolute', inset: 0, backgroundImage: backgroundImage && `url(${backgroundImage})` }}></ContentWrapper>}
              {backgroundVideo && <video style={{ objectFit: 'cover', position: 'absolute', inset: 0, width: '100%', height: '100%' }} autoPlay muted playsInline loop src={backgroundVideo}></video>}
              {((selectType === 'Email' && !dontShowEmailUntilSaved) && (
                <FullScreenVerticalCenterContent>
                  <h1>Progress Saved</h1>
                  <p>Follow the instructions in your email to continue your submission.</p>
                </FullScreenVerticalCenterContent>
              )) || ((selectType === 'Text' && !dontShowReportUntilSaved) && (
                <TextView saveChanges={this.saveChanges} onScroll={onScroll} />
              )) || (
                <TreeAnswerBox key={tree.key} tree={tree} saveProgress={this.saveProgress} onScroll={onScroll} />
              )}
            </ContentWrapper>
          </>)}
          {showDeleted && <EditingDisabled style={{ right }}><div>{project ? 'Submission' : 'App'} has been deleted</div></EditingDisabled>
          || showYouAreNotAuthorizedToEdit && <EditingDisabled style={{ right }}><div>You are not authorized to edit this submission</div></EditingDisabled>
          || showLoginToEditYourSubmission && <EditingDisabled style={{ right }}><div>Login to edit your submission</div></EditingDisabled>
          || showProjectLocked && <EditingDisabled style={{ right }}><div>Project Locked - Unlock to edit</div></EditingDisabled>}
          {isApp && !fullscreen && <Sidebar showMini={!showSidebar} tree={editingVersionOfTree} saveChanges={this.saveChanges} />}
          {isWidget && !isWidgetOnSameDomain && originalUrl.startsWith('/collections/') && <FullscreenWrapper style={{ left: 8 }}>
            <ClickableIcon small onClick={() => Navigation.back()} icon="fas fa-arrow-left" />
          </FullscreenWrapper>}
          <EmbedFullscreenButton />
          {submitEmail && <LoggedOutSavePopup tree={tree} onClose={this.loggedOutSavePopupClosed} />}
        </Wrapper>}
        {!hideContentForChatbutton && showToggle && <div
          style={{ position: 'fixed',
            top: 8,
            left: 8,
            display: 'flex',
            alignItems: 'center',
            zIndex: 4,
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: 'rgba(0, 0, 0, 0.65) 0px 1px 5px'
          }}
        >
          <ClickableIcon
            onClick={() => SidebarService.updateSelectType(showChatbot ? 'Tree' : 'Chat')}
            small icon={showChatbot ? "fas fa-tasks" : "fas fa-comment"}
          />
        </div>}
        {loading && <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={backgroundColor || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>}
        {showLoginToView && <div
          onClick={() => Navigation.push(Navigation.login)}
          style={{ position: 'absolute', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
        >
          <div style={{ color: 'white', padding: '1rem 2rem', borderRadius: '1rem', fontSize: Constants.SemiLargeFontSize, fontWeight: 'bold' }}>Login to view this content</div>
        </div>}
      </>
    );
  }
}
function getPlaceholderImage() {
  const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
  const ogImageUrl = ogImageMetaTag ? ogImageMetaTag.getAttribute('content') : null;
  return ogImageUrl ? (ogImageUrl.includes('/v1/images') ? `${ogImageUrl}&width=${window.innerWidth}&height=${window.innerHeight}` : ogImageUrl) : null;
}
function convertToGooglePreviewOrMobileBasicUrl(url) {
  try {
    // Parse the URL
    const urlParts = new URL(url);

    // Ensure it's a Google Docs URL
    if (!urlParts.hostname.includes("docs.google.com")) {
      throw new Error("URL is not a Google Docs or Sheets URL");
    }
    if (urlParts.pathname.includes("/d/e/")) {
      return url;
    }

    // Determine if it's a spreadsheet or document URL
    const isSpreadsheet = urlParts.pathname.includes("/spreadsheets/");
    const isDocument = urlParts.pathname.includes("/document/");

    if (!isSpreadsheet && !isDocument) {
      throw new Error("Unsupported URL type. Only Spreadsheets and Documents are handled.");
    }

    // Match the ID using a regular expression
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = url.match(regex);

    if (!match || match.length < 2) {
      throw new Error("Invalid URL format");
    }

    const id = match[1]; // Extract the ID

    // Extract query parameters and hash fragments if present
    const queryParams = urlParts.search; // Includes ? and query string
    const hashFragment = urlParts.hash; // Includes # and fragment

    if (isSpreadsheet) {
      // Construct the preview URL for spreadsheets
      return `https://docs.google.com/spreadsheets/d/${id}/preview${queryParams}${hashFragment}`;
    } else if (isDocument) {
      // Construct the mobilebasic URL for documents
      return `https://docs.google.com/document/d/${id}/preview${queryParams}${hashFragment}`;
    }

    return null; // Fallback, should not reach here
  } catch (error) {
    return null; // Return null if the URL is invalid
  }
}
function convertToEmbedUrl(url) {
  try {
    // Parse the URL
    const urlParts = new URL(url);

    // Validate it's a YouTube URL
    if (!urlParts.hostname.includes("youtube.com") && !urlParts.hostname.includes("youtu.be")) {
      throw new Error("Invalid YouTube URL");
    }

    let videoId;

    // Handle different URL formats
    if (urlParts.hostname === "youtu.be") {
      // Short URL format: https://youtu.be/<video_id>
      videoId = urlParts.pathname.slice(1); // Extract the video ID
    } else if (urlParts.pathname.startsWith("/watch") && urlParts.searchParams.has("v")) {
      // Standard watch URL format: https://www.youtube.com/watch?v=<video_id>
      videoId = urlParts.searchParams.get("v");
    } else if (urlParts.pathname.startsWith("/embed/")) {
      // Already in embed format: https://www.youtube.com/embed/<video_id>
      videoId = urlParts.pathname.split("/")[2];
    } else {
      throw new Error("No valid video ID found in the URL");
    }

    // Construct the embed URL
    const embedUrl = new URL(`https://www.youtube.com/embed/${videoId}`);

    // Add query parameters (excluding those irrelevant to embed format)
    urlParts.searchParams.forEach((value, key) => {
      embedUrl.searchParams.append(key, value);
    });

    return embedUrl.toString();
  } catch (error) {
    return null; // Return null if the URL is invalid
  }
}
function isPdfUrl(url) {
  try {
    // Parse the URL
    const urlParts = new URL(url);

    // Check if the pathname ends with ".pdf" (case-insensitive)
    return urlParts.pathname.toLowerCase().endsWith(".pdf");
  } catch (error) {
    return false; // Return false if the URL is invalid
  }
}
function validateAirtableUrl(url) {
  try {
    // Check if URL is a valid Airtable URL
    const airtablePattern = /^https:\/\/airtable\.com\//;
    if (!airtablePattern.test(url)) {
      return null; // Not an Airtable URL
    }

    // Check if the URL is a properly formatted embed URL
    const embedPattern = /https:\/\/airtable\.com\/embed\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+/;
    if (embedPattern.test(url)) {
      return true; // Proper embed URL
    }

    // Check for an improper Airtable URL (base or view without embed)
    const baseViewPattern = /https:\/\/airtable\.com\/(?:tbl|viw)[a-zA-Z0-9]+\/[a-zA-Z0-9]+/;
    if (baseViewPattern.test(url)) {
      return false; // Improper Airtable URL (Not Ready for Embed)
    }

    return null; // If no known pattern matches, return null (it is not an Airtable URL)
  } catch (error) {
    return null; // Return null if there is an error
  }
}
function convertToGoogleMapsEmbed(url) {
  try {
    // If the URL is already in the correct embed format with mid
    const embedPattern = /^https:\/\/www\.google\.com\/maps\/d\/embed\?mid=([a-zA-Z0-9-_]+)/;
    if (embedPattern.test(url)) {
      return url; // Return the URL as is if it is already in embed format
    }

    // Check if the URL is a valid Google Maps viewer URL with a mid
    const googleMapsPattern = /^https:\/\/www\.google\.com\/maps\/d\/u\/0\/viewer\?/;
    if (!googleMapsPattern.test(url)) {
      return null; // Not a Google Maps URL with a valid mid
    }

    // Match the mid parameter for a valid embed URL (example: https://www.google.com/maps/d/u/0/viewer?mid=...)
    const midPattern = /[?&]mid=([a-zA-Z0-9-_]+)/;
    const midMatch = url.match(midPattern);

    if (midMatch) {
      const mid = midMatch[1]; // Extract map ID
      return `https://www.google.com/maps/d/embed?mid=${mid}`; // Embed format with mid
    }

    // If no mid is found, return null (it's not a valid embed URL)
    return null;
  } catch (error) {
    return null; // Return null in case of error
  }
}
function isImageUrl(input) {
  if (!input) {
    return false; // Handle null or undefined input
  }

  // Common image file extensions
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff', 'ico', 'avif'];

  // Check if the input contains a MIME type or file extension
  const lowerInput = input.toLowerCase();

  // Check by file extension
  const extensionMatch = lowerInput.match(/\.(\w+)$/);
  if (extensionMatch) {
    const extension = extensionMatch[1]; // Extract the file extension
    if (imageExtensions.includes(extension)) {
      return true;
    }
  }

  // Check by MIME type
  if (lowerInput.startsWith('image/')) {
    return true;
  }

  return false; // Not an image
}
function convertToCanvaEmbedUrl(url) {
  try {
    // Regular expression to match Canva design URLs
    const regex = /https:\/\/www\.canva\.com\/design\/([a-zA-Z0-9-_]+)\/([a-zA-Z0-9-_]+)(?:\/[a-zA-Z]+)?/;
    const match = url.match(regex);

    // Check if the URL matches the expected pattern
    if (match && match.length >= 3) {
      const designId = match[1];
      const viewId = match[2];

      // Return the proper embed URL
      return `https://www.canva.com/design/${designId}/${viewId}/view?embed`;
    }

    // If not a valid Canva design URL, return null
    return null;
  } catch (error) {
    return null;
  }
}
function convertToGoogleCalendarEmbedUrl(url) {
  try {
    // Parse the input URL
    const urlParts = new URL(url);

    // Ensure the URL is a Google Calendar URL
    if (!urlParts.hostname.includes("calendar.google.com")) {
      return null; // Not a Google Calendar URL
    }

    // Extract query parameters
    const params = new URLSearchParams(urlParts.search);

    // Check for required parameters
    const calendarId = params.get("src");
    const timezone = params.get("ctz");

    if (!calendarId) {
      throw new Error("Missing calendar ID (src) in the URL");
    }

    // Construct the embed URL
    const embedUrl = new URL("https://calendar.google.com/calendar/embed");
    embedUrl.searchParams.set("src", calendarId);

    // Set timezone if available
    if (timezone) {
      embedUrl.searchParams.set("ctz", timezone);
    }

    return embedUrl.toString();
  } catch (error) {
    return null;
  }
}
function convertVimeoToEmbed(url) {
  const regex = /(?:https?:\/\/)?(?:www\.|player\.)?vimeo\.com\/(?:video\/)?(\d+)(?:\S*)?/;
  const match = url.match(regex);
  if (match && match[1]) {
      return `https://player.vimeo.com/video/${match[1]}`;
  }
  return null;
}
function convertInstagramReelToEmbedUrl(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/reel\/([A-Za-z0-9_-]+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return `https://www.instagram.com/reel/${match[1]}/embed/captioned`;
  }
  return null; // Returns null if the URL doesn't match the pattern
}

function convertTikTokToEmbedUrl(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@[\w.-]+\/video\/(\d+))/;
  const match = url.match(regex);
  if (match && match[1]) {
    return `https://www.tiktok.com/embed/${match[1]}`;
  }
  return null; // Returns null if the URL doesn't match the pattern
}