import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DrawerService from '../../services/Drawer';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import Navigation from '../../services/Navigation';
import Constants from '../../submodules/logictry_config/constants';
import WindowSize from '../../services/WindowSize';
import UserAvatar from '../UserAvatar';
import VerticalCenterDiv from '../../styledhtml/VerticalCenterDiv';
import ClickableDiv from '../ClickableDiv/index';
import Popup from '../Popup/index';
import MenuList from '../Popup/MenuList';
import MenuItem from '../Popup/MenuItem';
import Status from './Status';
import LoginButton from '../LoginButton';
import { AccountCompanies } from '../../services/Pagination/index';
import CompanyThemePopup from '../../components/CompanyThemePopup/index';

const OuterWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: ${Constants.DesktopHeaderHeight};
  z-index: 2;
`;
const Wrapper = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  height: ${Constants.DesktopHeaderHeight};

  ${Constants.MediaTabletAndDesktop} {
    height: ${Constants.DesktopHeaderHeight};
  }
  i {
    color: ${Constants.DarkTextColor};
  }
  span {
    color: ${Constants.DarkTextColor};
  }
`;
const DrawerAndTitle = styled.div`
  display: flex;
  align-items: center;
  > div {
    cursor: pointer;
    line-height: 100%;
  }
  > div:first-child {
    margin-left: 1.5rem;
  }
  > div:last-child {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
const Logo = styled.div`
  height: 32px;
  width: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
`;

const NavbarBackground = window.logictry && window.logictry.company && window.logictry.company.theme && window.logictry.company.theme.NavbarBackground;

export default class Header extends React.PureComponent {
  static propTypes = {
    fixed: PropTypes.bool,
  }
  state = {
    open: false,
    showEditTheme: false
  }

  componentDidMount() {
    UserAccount.onStateUpdate(this);
    Navigation.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    DrawerService.onStateUpdate(this);
    Company.onStateUpdate(this);
    AccountCompanies.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserAccount.offStateUpdate(this);
    Navigation.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    DrawerService.offStateUpdate(this);
    Company.offStateUpdate(this);
    AccountCompanies.offStateUpdate(this);
  }
  close = () => this.setState({ open: false, showTeams: false });
  clickedAccount = () => this.setState({ open: true });
  showTeams = () => this.setState({ showTeams: true });
  getButton = () => {
    if (!UserAccount.isLoggedIn) {
      return (
        <div style={{ marginRight: 16 }}>
          <LoginButton isApp />
        </div>
      );
    }
    return (
      <VerticalCenterDiv>
        <ClickableDiv style={{ width: 64 }} onClick={this.clickedAccount}>
          <UserAvatar buttonRef={(e) => { this.anchorEl = e; }} user={UserAccount.account} />
        </ClickableDiv>
      </VerticalCenterDiv>
    );
  }

  getLogo = (showMenu) => (UserAccount.isLoggedIn) ? (
    <DrawerAndTitle style={{ width: !WindowSize.mobile ? '272px' : null }}>
      {showMenu && <div onClick={this.toggleDrawer}>
        <i style={{ width: 14, textAlign: 'center' }} className="fas fa-bars"></i>
      </div>}
      {(!WindowSize.mobile || !showMenu) && <div onClick={() => Navigation.push(Navigation.home)}>
        <Logo style={{ backgroundImage: `url(${Constants.Logo})` }}></Logo>
      </div>}
    </DrawerAndTitle>
  ) : (
    <ClickableDiv
      onClick={() => {
        Navigation.push(Navigation.home);
      }}
      style={{ marginLeft: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Logo style={{ backgroundImage: `url(${Constants.Logo})` }}></Logo>
    </ClickableDiv>
  );
  toggleDrawer = () => DrawerService.toggle();
  backClicked = () => Navigation.back();
  getAccountPopup = () => {
    const { isAuthorizedToLogIn } = Company;
    const { currentPage } = AccountCompanies;
    return (
      <Popup
        allowBackgroundClose
        onClose={this.close}
        anchorEl={this.anchorEl}
        anchorBottomRight
        key={currentPage.length}
      >
        <MenuList>
          <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.accountSettings); }}>
            Settings
          </MenuItem>
          <MenuItem onClick={() => { this.close(); Navigation.push('/?content=submissions'); }}>
            Submissions
          </MenuItem>
          <MenuItem onClick={() => { this.close(); Navigation.push(`/?content=${!isAuthorizedToLogIn && 'submissions' || 'apps'}&state=deleted`); }}>
            Trash
          </MenuItem>
          <MenuItem onClick={() => { this.close(); UserAccount.logout() }}>
            Log Out
          </MenuItem>
          {Company.isExpected && Company.subscriptionVerified && (
            <>
              <div style={{ width: '100%', height: 1, backgroundColor: '#dbdbdb', margin: '0.5rem 0' }}></div>
              <MenuItem>
                <i style={{ marginRight: '0.5rem' }} className="fas fa-check" />{Company.company.longname}
              </MenuItem>
              {Company.isAuthorizedToLogIn && <MenuItem onClick={() => { this.close(); Navigation.push(Navigation.companySettings); }}>
                Company Settings
              </MenuItem>}
              {Company.isAuthorizedToLogIn && <MenuItem onClick={() => { this.close(); this.setState({ showEditTheme: true }); }}>
                Company Theme
              </MenuItem>}
            </>
          )}
          {(currentPage && currentPage.length > 0 && (
            <>
              <div style={{ width: '100%', height: 1, backgroundColor: '#dbdbdb', margin: '0.5rem 0' }}></div>
              {Company.isExpected && Company.subscriptionVerified && < MenuItem onClick={() => { window.location = Constants.isDevelopment ? 'https://localhost/login' : 'https://logictry.com/login'; }}>
                Personal Account
              </MenuItem>}
              {currentPage.map(({ _id, longname, shortname }) => {
                const newUrl = Constants.isDevelopment ? `https://${shortname}.logictry.localhost/login` : `https://${shortname}.logictry.com/login`;
                if (Company.isExpected && _id === Company.company._id) return null;
                return (
                  <MenuItem key={shortname} onClick={() => { window.location = newUrl; }}>
                    {longname}
                  </MenuItem>
                )
              })}
            </>
          ))}
        </MenuList>
      </Popup>
    );
  }

  render() {
    const { isAuthorizedToLogIn } = Company;
    const { showEditTheme } = this.state;
    const showDrawer = (isAuthorizedToLogIn);
    const { fixed } = this.props;
    const { mobile } = WindowSize;
    const { open } = DrawerService;
    const paddingTop = 'unset';
    const height = Constants.DesktopHeaderHeight;
    const borderBottom = (fixed && showDrawer) ? Constants.LightBorder : 'unset';
    const showPageContent = UserAccount.isLoggedIn && showDrawer && !(mobile && open);
    return (
      <OuterWrapper style={{ paddingTop, height }}>
        <Wrapper style={{ height, backgroundColor: !isAuthorizedToLogIn && NavbarBackground || Constants.White, borderBottom }}>
          {this.getLogo(showDrawer)}
          {showPageContent ? (
            <div style={{ flex: 1, textAlign: 'left' }}>{DrawerService.children}</div>
          ) : (
            <div style={{ flex: 1 }} />
          )}
          <Status />
          {this.getButton()}
        </Wrapper>
        {this.state.open && this.getAccountPopup()}
        {showEditTheme && <CompanyThemePopup open={showEditTheme} onClose={() => this.setState({ showEditTheme: false })} />}
      </OuterWrapper>
    );
  }
}
