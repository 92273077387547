import Server from '../services/server';

export function getTitleDescriptionAndImage(url) {
  return new Promise((resolve) => {
    try {
      const urlParts = new URL(url);
      if (!urlParts.hostname.includes("youtube.com") && !urlParts.hostname.includes("youtu.be")) {
        throw new Error("Invalid YouTube URL");
      }
      Server.__request({
        uri: `/v1/restfulapi?url=${url}`,
        method: 'GET',
      }).then((c) => {
        try {
          const parser = new DOMParser();
          const dom = parser.parseFromString(c.body, 'text/html');
          const title = dom.querySelector('title');
          const ogTitle = dom.querySelector('meta[property="og:title"]');
          const description = dom.querySelector('description');
          const ogDescription = dom.querySelector('meta[property="og:description"]');
          const ogImage = dom.querySelector('meta[property="og:image"]');
          const videoId = getVideoId(url);
          resolve({
            title: title && title.textContent || ogTitle && ogTitle.content,
            description: description && description.textContent || ogDescription && ogDescription.content,
            image: ogImage && ogImage.content || videoId && `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`
          });
        } catch (e) {
          resolve({});
        }
      });
    } catch(e) {
      resolve({});
    }
  });
}
function getVideoId(url) {
  try {
    // Parse the URL
    const urlParts = new URL(url);

    // Validate it's a YouTube URL
    if (!urlParts.hostname.includes("youtube.com") && !urlParts.hostname.includes("youtu.be")) {
      throw new Error("Invalid YouTube URL");
    }

    let videoId;

    // Handle different URL formats
    if (urlParts.hostname === "youtu.be") {
      // Short URL format: https://youtu.be/<video_id>
      videoId = urlParts.pathname.slice(1); // Extract the video ID
    } else if (urlParts.pathname.startsWith("/watch") && urlParts.searchParams.has("v")) {
      // Standard watch URL format: https://www.youtube.com/watch?v=<video_id>
      videoId = urlParts.searchParams.get("v");
    } else if (urlParts.pathname.startsWith("/embed/")) {
      // Already in embed format: https://www.youtube.com/embed/<video_id>
      videoId = urlParts.pathname.split("/")[2];
    } else {
      throw new Error("No valid video ID found in the URL");
    }

    // Construct the embed URL
    return videoId;
  } catch (error) {
    return null; // Return null if the URL is invalid
  }
}