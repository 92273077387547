import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import TreeEditBox from './TreeEditBox';
import { SINGLE_ANSWER_ATTR, FIXED_ATTR, OPEN_ENDED_ATTR, MULTIPLE_ANSWERS_ATTR, NONE_ATTR, CARD_ATTR, HORIZONTAL_ATTR, VERTICAL_ATTR, CONTENT_HEIGHT_ATTR, COLLAPSIBLE_ATTR } from '../../models/nodeattributes';
import { CATEGORY, TEXT } from '../../models/nodetypes';
import GoogleDriveCache from '../../services/cache/GoogleDriveCache';
import SelectMenu from '../../components/SelectMenu/index';
import Filter, { SelectionOptions } from '../../models/filter';
import recursivelyImportUnstructuredText from '../../submodules/logictry_wysiwyg/src/utils';
import Constants from '../../submodules/logictry_config/constants';
import Answerable from '../../models/answerable';
import Questionable from '../../models/questionable';
import Category from '../../models/category';
import Data from '../../models/data';
import SplitPane from '../../models/splitpane';
import Pane from '../../models/pane';
import FileGroup from '../../models/filegroup';
import Autofill from '../../models/autofill';
import Wiki from '../../models/wiki';
import DataSchemaField from '../../models/dataschemafield';
import ConditionalOperator from '../../models/conditionaloperator';
import CalculationOperator from '../../models/calculationoperator';
import Calculation from '../../models/calculation';
import SearchLogic from '../../models/searchlogic';
import Search from '../../models/search';
import Root from '../../models/root';
import AutoCreateLogic from '../../models/autocreatelogic';
import DataSchema from '../../models/dataschema';
import DataSchemaKey from '../../models/dataschemakey';
import DataSchemaValue from '../../models/dataschemavalue';
import Text from '../../models/text';
import { FONTS } from '../../utils/fonts';
import Report from '../../models/report';
import GrayOut from '../../services/TreeDisplay';
import { TextVisbilityOptions } from '../../models/node';
import TreeCreateNode from '../../services/TreeCreateNode';

const Divider = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  background-color: ${Constants.MediumBackground};
  padding: 0.25rem 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const SelectMenus = styled.div`
  margin: 0.5rem 10px;
  display: flex;
  flex-direction: column;
  select {
    width: 100%;
    padding-left: 20px;
    cursor: pointer;
  }
  input::placeholder {
    color: rgba(255,255,255,0.3);
  }
  input[type="text"] {
    ${Constants.InputBorderBottom}
    padding: 0;
    width: 100%;
    height: 22px;
    padding: 0px 8px;
  }
  input[type="color"] {
    padding: 0;
    width: 24px;
    height: 24px;
    padding: 0;
    flex-shrink: 0;
    cursor: pointer;
  }
  > div {
    height: 32px;
  }
`;
const SelectButton = styled.div`
  padding: 0 10px;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  &:hover {
    background-color: ${Constants.MediumBackground};
  }
`;
const SelectNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  > div: first-child {
    white-space: nowrap;
  }
  input {
    padding-left: 8px;
    width: 100%;
  }
`;

let showSettings = false;
let showBasicTypes = true;
let showAdvancedTypes = false;
let showActions = true;

export default class NodeSettings extends React.PureComponent {
  static propTypes = {
    node: PropTypes.object,
    parents: PropTypes.array,
    onClose: PropTypes.func,
  }
  componentDidMount() {
    this.__updateAutoCreateCallback = {};
    const { node } = this.props;
    if (node) node.onStateUpdate(this);
  }
  componentWillUnmount() {
    Object.keys(this.__updateAutoCreateCallback).forEach(this.removeUpdateAutoCreateCallback);
    const { node } = this.props;
    if (node) node.offStateUpdate(this);
  }
  moveToBranch = () => {
    const { node } = this.props;
    TreeCreateNode.createBranch(node);
    if (this.props.onClose) this.props.onClose();
  }
  duplicateChild = () => {
    const { node } = this.props;
    TreeCreateNode.duplicateNode(node);
    if (this.props.onClose) this.props.onClose();
  }
  convertNode = () => {
    const { tree } = GrayOut;
    const { node } = this.props;

    let parentIndex = node.parent.children.indexOf(node) + 1;
    const categories = recursivelyImportUnstructuredText(node.text);
    categories.children.forEach((c, i) => {
      recursivelyCreateNodes(c, node.parent, true);
    });
    function recursivelyCreateNodes(node, parentNode, isParent) {
      if (node.text) {
        const nextIndex = isParent ? parentIndex : parentNode.children.length;
        parentIndex += 1;
        if (node.type === TEXT) {
          tree.createType(TEXT, parentNode, nextIndex, [TEXT], node.text, true);
        } else if (node.type === CATEGORY) {
          const newChild = tree.createType(CATEGORY, parentNode, nextIndex, [CATEGORY], node.text, true);
          node.children.forEach((c) => {
            recursivelyCreateNodes(c, newChild);
          });
        }
      } else {
        node.children.forEach((c) => {
          recursivelyCreateNodes(c, parentNode, isParent);
        });
      }
    }
    node.parent.deleteChild(node);
    if (this.props.onClose) this.props.onClose();
  }
  deleteChild = () => {
    const { node, onDeleteAnswer } = this.props;
    const { parents } = node;
    if (onDeleteAnswer) onDeleteAnswer(node);
    else  {
      const parent = parents[parents.length - 1];
      parent.deleteChild(node);
    }
    if (this.props.onClose) this.props.onClose();
  }
  getTextVisibilitySetting = (activeObject) => {
    const { textVisibilitySetting } = activeObject;
    return <SelectMenu noPadding value={textVisibilitySetting} onChange={(value) => { this.changeValue(activeObject, 'textVisibility', value); }} options={TextVisbilityOptions} />;
  }
  getSelectionSetting = (activeObject) => {
    if (!(activeObject instanceof Filter)) return null;
    const { selectionType } = activeObject;
    return <SelectMenu noPadding value={selectionType} onChange={(value) => { this.changeValue(activeObject, 'selectionType', value); }} options={SelectionOptions} />;
  }
  changeValue = (activeObject, type, value) => {
    if (type === 'answerQuantity') activeObject.updateAnswerQuantity(value);
    else if (type === 'answerChoices') activeObject.updateAnswerChoiceType(value);
    else if (type === 'answerInputType') activeObject.updateAnswerInputType(value);
    else if (type === 'userInputFormat') activeObject.updateAnswerFormatType(value);
    else if (type === 'textVisibility') activeObject.updateTextVisibilityOption(value);
    else if (type === 'selectionType') activeObject.updateSelectionType(value);
  }
  updateAutoCreate(activeObject) {
    const drive = GoogleDriveCache.get(activeObject.text);
    if (!this.__updateAutoCreateCallback[activeObject.key]) this.__updateAutoCreateCallback[activeObject.key] = () => this.updateAutoCreate(activeObject);
    if (!drive) {
      GoogleDriveCache.onStateUpdate(this.__updateAutoCreateCallback[activeObject.key]);
      return this.forceUpdate();
    }
    this.removeUpdateAutoCreateCallback(activeObject.key);
    activeObject.convertDriveToTree(drive);
    return this.forceUpdate();
  }
  removeUpdateAutoCreateCallback = (key) => {
    GoogleDriveCache.offStateUpdate(this.__updateAutoCreateCallback[key]);
    delete this.__updateAutoCreateCallback[key];
  }
  getActiveObjectSettings = (activeObject) => {
    const { editing } = GrayOut;
    if (!editing) return null;
    if (activeObject instanceof Questionable) {
      const { choicesVisibilityOption, choicesVisibilityOptions, userInputType, answerChoiceType, multipleAnswers, userInputTypesOptions, answerQuantityOptions, answerChoiceTypeOptions, showAnswerInputFormatOptions, userInputFormat, userInputFormatOptions, sortOption, sortOptions, horizontal, directionOptions, wrapOption, wrapOptions, showFilteredOption, showFilteredOptions, requiredOption, requiredOptions, showChildrenAsOption, showChildrenAsOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={userInputType} onChange={(value) => { this.changeValue(activeObject, 'answerInputType', value); }} options={userInputTypesOptions} />
          {this.getTextVisibilitySetting(activeObject)}
          {showFilteredOption && showFilteredOptions && <SelectMenu noPadding value={showFilteredOption} onChange={(value) => { activeObject.showFilteredOption = value; }} options={showFilteredOptions} />}
          {this.getSelectionSetting(activeObject)}
          <SelectMenu noPadding value={multipleAnswers ? MULTIPLE_ANSWERS_ATTR : SINGLE_ANSWER_ATTR} onChange={(value) => { this.changeValue(activeObject, 'answerQuantity', value); }} options={answerQuantityOptions} />
          <SelectMenu noPadding value={answerChoiceType} onChange={(value) => { this.changeValue(activeObject, 'answerChoices', value); }} options={answerChoiceTypeOptions} />
          {showAnswerInputFormatOptions && <SelectMenu noPadding value={userInputFormat} onChange={(value) => { this.changeValue(activeObject, 'userInputFormat', value); }} options={userInputFormatOptions} />}
          <SelectMenu noPadding value={sortOption} onChange={(value) => { activeObject.sortOption = value; }} options={sortOptions} />
          <SelectMenu noPadding value={horizontal ? HORIZONTAL_ATTR : VERTICAL_ATTR} onChange={(value) => { activeObject.updateDirectionOption(value); }} options={directionOptions} />
          <SelectMenu noPadding value={wrapOption} onChange={(value) => { activeObject.wrapOption = value; }} options={wrapOptions} />
          <SelectMenu noPadding value={requiredOption} onChange={(value) => { activeObject.requiredOption = value; }} options={requiredOptions} />
          <SelectMenu noPadding value={choicesVisibilityOption} onChange={(value) => { activeObject.choicesVisibilityOption = value; }} options={choicesVisibilityOptions} />
          <SelectMenu noPadding value={showChildrenAsOption} onChange={(value) => { activeObject.showChildrenAsOption = value; }} options={showChildrenAsOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Root) {
      const { saveOnSubmit, maxWidth, backgroundColor, backgroundImage, primaryColorSetting, fontFamily } = activeObject;
      return (
        <SelectMenus>
          <SelectNumber><div>Max Width:</div><input
            value={maxWidth}
            onChange={(e) => activeObject.updateMaxWidth(e.target.value)}
            type="text"
            placeholder="100%"
          ></input></SelectNumber>
          <SelectNumber><div>Auto Save:</div><input
            value={saveOnSubmit}
            onChange={(e) => activeObject.updateSaveOnSubmit(e.target.value)}
            type="text"
            placeholder="Node"
          ></input></SelectNumber>
          <SelectNumber>
            <div>Background Color:</div>
            <input
              value={backgroundColor}
              onChange={(e) => activeObject.updateBackgroundColor(e.target.value)}
              type="text"
              placeholder="Node"
            ></input>
            <input
              value={backgroundColor}
              onChange={(e) => activeObject.updateBackgroundColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber>
            <div>Background Image:</div>
            <input
              value={backgroundImage}
              onChange={(e) => activeObject.updateBackgroundImage(e.target.value)}
              type="text"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber>
            <div>Primary Color:</div>
            <input
              value={primaryColorSetting}
              onChange={(e) => activeObject.updatePrimaryColor(e.target.value)}
              type="text"
              placeholder="Node"
            ></input>
            <input
              value={primaryColorSetting}
              onChange={(e) => activeObject.updatePrimaryColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber>
            <div>Font Style:</div>
            <select defaultValue={fontFamily || 'Montserrat'} onChange={(e) => { activeObject.fontFamily = e.target.value; }}>
              {FONTS.map((font) => (
                <option key={font} value={font} style={{ fontFamily: font }}>
                  {font}
                </option>
              ))}
            </select>
          </SelectNumber>
        </SelectMenus>
      );
    }
    if (activeObject instanceof Report) {
      const { backgroundColor, backgroundImage, primaryColorSetting, fontFamily } = activeObject;
      return (
        <SelectMenus>
          <SelectNumber>
            <div>Background Color:</div>
            <input
              value={backgroundColor}
              onChange={(e) => activeObject.updateBackgroundColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber>
            <div>Background Image:</div>
            <input
              value={backgroundImage}
              onChange={(e) => activeObject.updateBackgroundImage(e.target.value)}
              type="text"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber>
            <div>Primary Color:</div>
            <input
              value={primaryColorSetting}
              onChange={(e) => activeObject.updatePrimaryColor(e.target.value)}
              type="text"
              placeholder="Node"
            ></input>
            <input
              value={primaryColorSetting}
              onChange={(e) => activeObject.updatePrimaryColor(e.target.value)}
              type="color"
              placeholder="Node"
            ></input>
          </SelectNumber>
          <SelectNumber>
            <div>Font Style:</div>
            <select defaultValue={fontFamily || 'Montserrat'} onChange={(e) => { activeObject.fontFamily = e.target.value; }}>
              {FONTS.map((font) => (
                <option key={font} value={font} style={{ fontFamily: font }}>
                  {font}
                </option>
              ))}
            </select>
          </SelectNumber>
        </SelectMenus>
      );
    }
    if (activeObject instanceof AutoCreateLogic) {
      const { dataSourceOption, dataSourceOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={dataSourceOption} onChange={(value) => { activeObject.dataSourceOption = value; }} options={dataSourceOptions} />
          <SelectButton onClick={() => this.updateAutoCreate(activeObject)}>{this.__updateAutoCreateCallback[activeObject.key] ? 'Updating...' : 'Update'}</SelectButton>
        </SelectMenus>
      );
    }
    if (activeObject instanceof SearchLogic) {
      const { searchTypeOption, searchTypeOptions, location, threshold, distance, minMatchCharLength } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={searchTypeOption} onChange={(value) => { activeObject.searchTypeOption = value; }} options={searchTypeOptions} />
          {this.getSelectNumber('Location:', location, (val) => activeObject.updateLocation(val), '0', '100')}
          {this.getSelectNumber('Threshold:', threshold, (val) => activeObject.updateThreshold(val), '0', '1')}
          {this.getSelectNumber('Distance:', distance, (val) => activeObject.updateDistance(val), '0', '1000')}
          {this.getSelectNumber('MinCharMatchLength:', minMatchCharLength, (val) => activeObject.updateMinCharMatchLength(val), '0', '30')}
        </SelectMenus>
      );
    }
    if (activeObject instanceof Search) {
      const { inputTypeOption, inputTypeOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={inputTypeOption} onChange={(value) => { activeObject.inputTypeOption = value; }} options={inputTypeOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof ConditionalOperator || activeObject instanceof CalculationOperator) {
      const { typeOption, typeOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={typeOption} onChange={(value) => { activeObject.typeOption = value; }} options={typeOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchema) {
      const { formatOption, formatOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={formatOption} onChange={(value) => { activeObject.formatOption = value; }} options={formatOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchemaField) {
      const { sortOption, sortOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={sortOption} onChange={(value) => { activeObject.sortOption = value; }} options={sortOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchemaKey) {
      const { layoutOption, layoutOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={layoutOption} onChange={(value) => { activeObject.layoutOption = value; }} options={layoutOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof DataSchemaValue) {
      const { typeOption, typeOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={typeOption} onChange={(value) => { activeObject.typeOption = value; }} options={typeOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Answerable) {
      const { preselectOption, preselectOptions, inputTypeOption, inputTypeOptions } = activeObject;
      return (
        <SelectMenus>
          {this.getTextVisibilitySetting(activeObject)}
          <SelectMenu noPadding value={preselectOption} onChange={(value) => { activeObject.preselectOption = value; }} options={preselectOptions} />
          {inputTypeOption && inputTypeOptions && <SelectMenu noPadding value={inputTypeOption} onChange={(value) => { activeObject.inputTypeOption = value; }} options={inputTypeOptions} />}
        </SelectMenus>
      );
    }
    if (activeObject instanceof FileGroup) {
      const { openEnded, selectOption, requiredOption, requiredOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={openEnded ? OPEN_ENDED_ATTR : FIXED_ATTR} onChange={(value) => { activeObject.updateAnswerChoiceType(value); }} options={selectOption} />
          <SelectMenu noPadding value={requiredOption} onChange={(value) => { activeObject.requiredOption = value; }} options={requiredOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Category) {
      const { card, displayOptions, defaultCategorySetting, expandedOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={card ? CARD_ATTR : NONE_ATTR} onChange={(value) => { activeObject.updateDisplayOption(value); }} options={displayOptions} />
          {this.getTextVisibilitySetting(activeObject)}
          <SelectMenu noPadding value={defaultCategorySetting} onChange={(value) => { activeObject.updateExpandedOption(value); }} options={expandedOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Calculation) {
      const { decimals } = activeObject;
      return (
        <SelectMenus>
          {this.getTextVisibilitySetting(activeObject)}
          {this.getSelectNumber('Decimals:', decimals, (val) => activeObject.updateDecimals(val), '0', '10')}
        </SelectMenus>
      );
    }
    if (activeObject instanceof Autofill) {
      const { readWriteOption, readWriteOptions, delimiter, delimiterOptions, dataSource, dataSourceOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={delimiter} onChange={(value) => { activeObject.updateDelimiterOption(value); }} options={delimiterOptions} />
          <SelectMenu noPadding value={dataSource} onChange={(value) => { activeObject.updateDataSourceOption(value); }} options={dataSourceOptions} />
          <SelectMenu noPadding value={readWriteOption} onChange={(value) => { activeObject.readWriteOption = value; }} options={readWriteOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Data) {
      const { delimiter, delimiterOptions, dataSource, dataSourceOptions, readWriteOption, readWriteOptions, emptyCellsOption, emptyCellsOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={delimiter} onChange={(value) => { activeObject.updateDelimiterOption(value); }} options={delimiterOptions} />
          <SelectMenu noPadding value={dataSource} onChange={(value) => { activeObject.updateDataSourceOption(value); }} options={dataSourceOptions} />
          <SelectMenu noPadding value={readWriteOption} onChange={(value) => { activeObject.readWriteOption = value; }} options={readWriteOptions} />
          <SelectMenu noPadding value={emptyCellsOption} onChange={(value) => { activeObject.emptyCellsOption = value; }} options={emptyCellsOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof SplitPane) {
      const { horizontal, directionOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={horizontal ? HORIZONTAL_ATTR : VERTICAL_ATTR} onChange={(value) => { activeObject.updateDirectionOption(value); }} options={directionOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Pane) {
      const { contentHeight, sizingOptions, collapsible, resizingOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={contentHeight ? CONTENT_HEIGHT_ATTR : NONE_ATTR} onChange={(value) => { activeObject.updateSizingOption(value); }} options={sizingOptions} />
          <SelectMenu noPadding value={collapsible ? COLLAPSIBLE_ATTR : NONE_ATTR} onChange={(value) => { activeObject.updateResizingOption(value); }} options={resizingOptions} />
        </SelectMenus>
      );
    }
    if (activeObject instanceof Wiki) {
      const { maxDepth, contentFormatOption, contentFormatOptions, showProgressOption, showProgressOptions, navButtonsOption, navButtonsOptions, pageTitlesOption, pageTitlesOptions } = activeObject;
      return (
        <SelectMenus>
          <SelectMenu noPadding value={contentFormatOption} onChange={(value) => { activeObject.contentFormatOption = value; }} options={contentFormatOptions} />
          <SelectMenu noPadding value={showProgressOption} onChange={(value) => { activeObject.showProgressOption = value; }} options={showProgressOptions} />
          <SelectMenu noPadding value={navButtonsOption} onChange={(value) => { activeObject.navButtonsOption = value; }} options={navButtonsOptions} />
          <SelectMenu noPadding value={pageTitlesOption} onChange={(value) => { activeObject.pageTitlesOption = value; }} options={pageTitlesOptions} />
          {this.getSelectNumber('Max Depth:', maxDepth, (val) => activeObject.updateMaxDepth(val), '1', '3')}
        </SelectMenus>
      );
    }
    if (activeObject instanceof Text) {
      return (
        <SelectMenus>
          {this.getTextVisibilitySetting(activeObject)}
        </SelectMenus>
      )
    }
    return null;
  }
  getSelectNumber(text, defaultValue, onUpdate, min, max) {
    return (
      <SelectNumber><div>{text}</div><input
        defaultValue={defaultValue}
        onChange={(e) => onUpdate(e.target.value)}
        onKeyUp={(e) => onUpdate(e.target.value)}
        onBlur={(e) => onUpdate(e.target.value)}
        type="number"
        min={min}
        max={max}
      ></input></SelectNumber>
    )
  }
  toggleExpanded = () => {
    const { node } = this.props;
    const expanded = GrayOut.isExpanded(node);
    GrayOut.setExpanded(node, !expanded);
    if (this.props.onClose) this.props.onClose();
  }
  toggleExpandedAll = (expand) => {
    const { node } = this.props;
    GrayOut.setExpanded(node, expand, true);
    if (this.props.onClose) this.props.onClose();
  }
  render() {
    const { node } = this.props;
    const { tree } = GrayOut;
    const { isDisabled } = tree;
    const expanded = GrayOut.isExpanded(node);
    const isMoveToBranchAllowed = TreeCreateNode.isMoveToBranchAllowed(node);
    const isDeleteAllowed = TreeCreateNode.isDeleteAllowed(node);
    const isDuplicateAllowed = TreeCreateNode.isDuplicateAllowed(node);
    const showOtherOptions = !isDisabled && (isMoveToBranchAllowed || isDeleteAllowed);
    const activeSettings = this.getActiveObjectSettings(node);
    return(
      <>
        <Divider onClick={() => { showSettings = !showSettings; this.forceUpdate(); }}><div>{`${node.attributes[0]} Settings`}</div>{activeSettings && <i className={showSettings ? 'fas fa-caret-up' : 'fas fa-caret-down' } />}</Divider>
        {showSettings && activeSettings}
        <Divider onClick={() => { showBasicTypes = !showBasicTypes; this.forceUpdate(); }}><div>Basic Types</div><i className={showBasicTypes ? 'fas fa-caret-up' : 'fas fa-caret-down' } /></Divider>
        {showBasicTypes && <TreeEditBox basicTypes node={node} onClose={this.props.onClose} />}
        <Divider onClick={() => { showAdvancedTypes = !showAdvancedTypes; this.forceUpdate(); }}><div>Advanced Types</div><i className={showAdvancedTypes ? 'fas fa-caret-up' : 'fas fa-caret-down' } /></Divider>
        {showAdvancedTypes && (
          <TreeEditBox node={node} onClose={this.props.onClose} />
        )}
        <Divider onClick={() => { showActions = !showActions; this.forceUpdate(); }}><div>{`Actions`}</div><i className={showActions ? 'fas fa-caret-up' : 'fas fa-caret-down' } /></Divider>
        {showActions && <>
          <SelectButton onClick={this.toggleExpanded}>{expanded ? 'Collapse Node' : 'Expand Node'}</SelectButton>
          <SelectButton onClick={() => this.toggleExpandedAll(false)}>Collapse All</SelectButton>
          <SelectButton onClick={() => this.toggleExpandedAll(true)}>Expand All</SelectButton>
          {showOtherOptions && <>
            {isMoveToBranchAllowed && <SelectButton onClick={this.moveToBranch}>Move to Branch</SelectButton>}
            {isDuplicateAllowed && <SelectButton onClick={this.duplicateChild}>Duplicate Node</SelectButton>}
            {node.children.length === 0 && <SelectButton onClick={this.convertNode}>Convert Node</SelectButton>}
            {isDeleteAllowed && <SelectButton onClick={this.deleteChild}>Delete Node</SelectButton>}
          </>}
        </>}
      </>
    );
  }
}