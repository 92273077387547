/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import AutoGrowTextArea from '../AutoGrowTextArea';
import Constants from '../../submodules/logictry_config/constants';
import { CATEGORY, SUB_TREE, TEXT } from '../../models/nodetypes';
import OpenAI from '../../services/OpenAI';
import StatefulButton from '../StatefulButton';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  background: white;
  > div {
    overflow: auto;
  }
  > div:first-child {
    padding: 1rem;
    flex: 1;
    position: absolute;
    inset: 0;
    > div {
      width: 100%;
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        width: 100%;
        max-width: 48rem;
      }
    }
  }
  > div:last-child {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0rem;
    width: 100%;
    align-items: center;
    > div:first-child {
      flex: 1;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 48rem;
      background: white;
      background: ${Constants.LightBackground};
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;
      border-radius: 0.5rem;
      padding: 0 1rem;
      > div:last-child {
        margin-left: 0.5rem;
        height: 40px;
      }
    }
    button {
      background: ${Constants.PrimaryColor};
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
    }
  }
`;

export default class ChatInterface extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.object,
    style: PropTypes.object,
  }
  constructor(props) {
    super();
    const { tree } = props;
    const nodes = tree.root.__recursivelyGetAllNodesByTypes(tree.root, [TEXT]);
    let maxLength = 0;
    nodes.forEach(({ text }) => {
      const words = text.split(/\s+/);
      maxLength = Math.max(maxLength, words.length);
    });
    this.state = {
      question: '',
      messages: [{}],
      busy: false,
      tooLong: maxLength > 4000
    }
  }
  componentDidMount() {
    OpenAI.onStateUpdate(this.openAISubmission);
    const { tree } = this.props;
    const initialText = `Hi, I am a chatbot capable of answering any questions relating to "${tree.title.text}".  What would you like to ask?`;
    this.initialTextInterval = setInterval(() => {
      const currentText = this.state.messages[0].answer || '';
      const newText = initialText.slice(0, currentText.length + 1);
      const busy = newText !== currentText;
      this.setState({
        messages: [{
          answer: newText
        }],
        busy
      });
      if (!busy) clearInterval(this.initialTextInterval);
    }, 20);
  }
  componentWillUnmount() {
    OpenAI.offStateUpdate(this.openAISubmission);
  }
  openAISubmission = () => {
    const { messages } = this.state;
    const { response, complete } = OpenAI.openAIState;
    messages[messages.length - 1].answer = response;
    if (complete) this.setState({ busy: false });
    this.scrollBottom();
    this.forceUpdate();
  }
  askQuestion = async () => {
    const { question } = this.state;
    if (!question) return;
    if (this.state.busy) return;
    this.setState({ busy: true, question: '' });

    const { messages } = this.state;
    let answer = '';
    messages.push({ question });
    messages.push({ answer });
    this.setState({ messages });
    this.scrollBottom();

    const { tree } = this.props;
    const subtreeNodes = tree.root.__recursivelyGetAllNodesByTypes(tree.root, [SUB_TREE]);
    if (subtreeNodes && subtreeNodes.length > 0) {
      OpenAI.askQuestionServerSide(question, subtreeNodes.map(({ text }) => text));
    } else {
      const textNodes = tree.root.__recursivelyGetAllNodesByTypes(tree.root, [TEXT]);
      let information = [];
      textNodes.forEach(({ text, parents }) => {
        let preText = ''
        parents.forEach((parent) => {
          if (parent.isType(CATEGORY)) preText += `${preText}${parent.text} -> `;
        });
        information.push(`${preText}${text}`);
      });
      OpenAI.askQuestionClientSide(tree._id, question, information);
    }
  }
  scrollBottom = () => {
    setTimeout(() => {
      const chatbotMessages = document.getElementById('chatinterface__messages');
      if (chatbotMessages) {
        chatbotMessages.scrollTop = chatbotMessages.scrollHeight;
      }
    });
  }
  render() {
    const { style } = this.props;
    const { messages, busy, question, tooLong } = this.state;
    if (tooLong) return <Wrapper style={style}><FullScreenVerticalCenterContent>
      <h1>Chatbot Disabled</h1>
      <p>The content is too long and must be reformatted.</p>
    </FullScreenVerticalCenterContent>
    </Wrapper>;
    return (
      <Wrapper style={style}>
        <div id="chatinterface__messages">
          {messages.map(({ question, answer }) => (
            (question || answer) && <div key={question || answer} style={{ backgroundColor: answer && Constants.LightBackground || null }}><div>{question || answer}</div></div>
          ))}
          <div style={{ height: '8rem' }}></div>
        </div>
        <div>
          <div onKeyDown={(e) => { if (e.key === 'Enter') this.askQuestion(); }}>
            <AutoGrowTextArea
              disabled={false}
              tabIndex={-1}
              onChange={(question) => this.setState({ question })}
              onBlur={this.onBlur}
              onFocus={this.addActive}
              placeholder={'Send a message'}
              defaultValue={question}
              className={''}
              padding={'1.5rem 0'}
            />
            <div>
              <StatefulButton
                onClick={this.askQuestion}
                text="Submit"
                state={busy ? 'busy' : null}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
