import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import UserCache from '../../services/cache/UserCache';
import TreeCache from '../../services/cache/TreeCache';
import { TreeJsonToTreeText } from '../../models/tree';
import Constants from '../../submodules/logictry_config/constants';
import Popup from '../../components/Popup/index';
import Wysiwyg from '../../submodules/logictry_wysiwyg/src/js';
import { convertStructuredTextIntoLogicSyntax } from '../../submodules/logictry_wysiwyg/src/utils';
import GoInteractive from '../../components/GoInteractive';
import OpenAI from '../../services/OpenAI';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 6rem);
  width: 720px;
  height: 100%;
  padding: 0 2rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 6rem);
  h1 {
    text-align: center;
  }
  h2 {
    margin: 1rem 0;
    font-size: ${Constants.NormalFontSize};
  }

  input {
    padding: 0.5rem 1rem;
    width: 100%;
  }
  > div:nth-child(1) {
    display: flex;
    gap: 2rem;
    margin: 1.5rem 0;
    padding-bottom: 8px;
    > div {
      position: relative;
    }
    > div.underline {
      // color: ${Constants.PrimaryColor};
      font-weight: 500;
    }
    > div.underline::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: black;
    }
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  > button {
    flex: 1;
    max-width: 210px;
    padding: 0.5rem 1.5rem;
    color: white;
    background: ${Constants.PrimaryColor};
    border-radius: 0.5rem;
  }
`;
const SearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 784px;
  input {
    flex: 1;
    border: 1px solid rgb(193, 193, 193);
    border-radius: 0.5rem;
  }
`;

export default class CreateTemplatePopup extends React.PureComponent {
  static propTypes = {
    treeOrSpreadsheet: PropTypes.object,
    team: PropTypes.string,
    onClose: PropTypes.func,
  }
  state = {
    html: '',
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    TreeCache.onStateUpdate(this);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    if (this.wysiwyg) {
      this.wysiwyg.unmount();
      this.wysiwyg = null;
    }
    TreeCache.offStateUpdate(this);
  }
  onRef = (mountNode) => {
    if (this.wysiwyg || !mountNode) return;
    this.wysiwyg = new Wysiwyg(mountNode, {
      onBlur: null,
      height: '100%',
      width: '100%',
      focusOnInit: null,
      fullscreen: true,
      disabled: false,
      initialText: '',
      onTextChanged: (html) => this.setState({ html }),
      borderRadius: 8
    });
  }
  create = () => {
    const { onClose, team } = this.props;
    const { html } = this.state;
    const initialJSON = {
      children: [
        {
          text: '',
          attributes: ['Title'],
          children: [],
        },
        {
          text: '',
          attributes: ['Description'],
          children: [],
        },
        {
          text: '',
          attributes: ['Root'],
          children: [],
        }
      ]
    };
    let newTreeText = '';
    if (html) {
      convertStructuredTextIntoLogicSyntax(html, initialJSON);
      newTreeText = TreeJsonToTreeText(initialJSON);
    }
    const tree = TreeCache.createNew(newTreeText);
    if (team) tree.addTeam(team);
    TreeCache.createTree(tree);
    onClose();
  }
  render() {
    const { onClose } = this.props;
    const { generating, html } = this.state;
    return (
      <Popup
        onClose={(!generating || generating === 'complete') && onClose || null}
        allowBackgroundClose
        showCloseButton
      >
        <Wrapper>
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '1.5rem', padding: '0 0 1rem 0' }}>
              <h1>Create New App</h1>
              <div>
                <h2>1. Create a blank App</h2>
                <Buttons style={{ width: '14rem' }}>
                  <button onClick={this.create}>
                    Create blank app
                  </button>
                </Buttons>
              </div>
              <div>
                <h2>2. Analyze your website</h2>
                <SearchBar>
                  <input id="GOINTERACTIVE__WEBSITE" placeholder="Your Website (URL)" type="text" defaultValue={OpenAI.website}></input>
                  <Buttons>
                    <button
                      onClick={() => {
                        if (!document.getElementById('GOINTERACTIVE__WEBSITE').value) return;
                        this.setState({ generating: 'website' });
                      }}
                    >Analyze website!</button>
                  </Buttons>
                </SearchBar>
              </div>
              <div>
                <h2>3. Start from existing content (or prompt)</h2>
                <div ref={this.onRef}></div>
                <Buttons style={{ marginTop: '1rem' }}>
                  <button onClick={this.create}>
                    Generate content
                  </button>
                  <button onClick={() => {
                    if (!html) return;
                    this.setState({ generating: 'prompt' })
                  }}>
                    Generate with A.I.
                  </button>
                  <button onClick={() => {
                    if (!html) return;
                    this.setState({ generating: 'suggestions' })
                  }}>
                    Generate suggestions
                  </button>
                </Buttons>
              </div>
            </div>
          </div>
          {generating && <div style={{ position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column', background: 'white' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '1rem 2.5rem' }}>
              {generating === 'complete'  && <i onClick={() => this.setState({ generating: null })} className="fas fa-arrow-left"></i>}
              <h2>{generating === 'complete' ? 'Suggestions' : 'Generating Suggestions'}</h2>
            </div>
            <div style={{ position: 'relative', flex: 1 }}>
              <GoInteractive
                html={generating === 'suggestions' && html || null}
                website={generating === 'website' && document.getElementById('GOINTERACTIVE__WEBSITE').value || null}
                type={generating === 'prompt' && 'assessment' || null}
                description={generating === 'prompt' && html || null}
                onComplete={() => this.setState({ generating: 'complete' })}
              />
            </div>
          </div>}
        </Wrapper>
      </Popup>
    );
  }
}
