import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import LogicBaseCollectionCache from '../../services/cache/LogicBaseCollectionCache';
import UserCache from '../../services/cache/UserCache';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserAccount from '../../services/UserAccount';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';
import LoadingIndicator from '../../components/LoadingIndicator';
import UserProfileHeader from '../../components/UserProfileHeader';
import Scrollable from '../../components/Scrollable';
import shortenLikesCount from '../../utils/shortenLikesCount';
import LogicBaseCollectionRow from '../../components/LogicBaseCollectionRow';
import ClickableIcon from '../../components/ClickableIcon';
import Connect from '../../services/Connect';
import ConnectAutoHeight from '../TreeAnswerPage/ConnectAutoHeight';
import Popup from '../../components/Popup';
import MenuList from '../../components/Popup/MenuList';
import MenuItem from '../../components/Popup/MenuItem';
import SocialSharePopup from '../../components/SocialShare/SocialSharePopup';
import EmbedCodePopup from '../../components/ObjectSettings/EmbedCodePopup';
import CollectionSubscribeButton from '../../components/CollectionSubscribeButton';
import EmbedFullscreenButton from '../../components/EmbedFullscreenButton';
import InviteSubscribers from '../../components/ObjectSettings/InviteSubscribers';
import ConfirmDialog from '../../components/ConfirmDialog';
import LogicBaseCollectionEditPopup from '../../components/LogicBaseCollectionEditPopup';
import PopupService from '../../services/Popup';

const UserProfile = styled.div`
  color: ${Constants.Gray};
  h1 {
    font-weight: 600;
    margin: 0;
    font-size: ${Constants.VeryLargeFontSize};
    ${Constants.MediaMobile} {
      font-size: ${Constants.LargeFontSize};
    }
    color: ${Constants.DarkText};
  }
  p {
    margin: 0;
  }
  > div {
    margin: auto;
    display: flex;
    flex-direction: column;
    margin: auto;
    > div:nth-child(3) {
      margin-bottom: 0.5rem;
      font-size: ${Constants.SmallFontSize};
    }
  }
`;
const MenuWrapper = styled.div`
  i {
    margin-right: 1rem;
    justify-content: center;
    display: flex;
    width: 1rem;
  }
`;
const TitleSection = styled.div`
  margin: 1rem 0 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${Constants.MediaMobile} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  > div {
    gap: 0 0.5rem;
  }
`;

export default class LogicBaseCollectionPage extends React.PureComponent {
  state = {
    overrideRelationship: null,
    appname: Navigation.currentLocation[2],
    menuOpen: false,
    showEditCollectionPopup: false,
    confirmDelete: false,
  }
  componentDidMount() {
    LogicBaseCollectionCache.onStateUpdate(this);
    UserCache.onStateUpdate(this);
    UserAccount.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    const { email } = Navigation.currentSearch;
    if (email) {
      UserAccount.registerAccount({ email }, true).then(() => {
        setTimeout(() => {
          Navigation.getQueryParameter('email');
        }, 500);
      });
    }
  }
  componentWillUnmount() {
    LogicBaseCollectionCache.offStateUpdate(this);
    UserCache.offStateUpdate(this);
    UserAccount.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
  }
  delete = () => {
    const { appname } = this.state;
    const collection = LogicBaseCollectionCache.get(appname);
    if (collection && collection._id) LogicBaseCollectionCache.delete(collection);
    this.setState({ confirmDelete: false });
  }
  render() {
    const { email } = Navigation.currentSearch;
    const { scrollDisabled } = Connect;
    const { appname, menuOpen, showShareLinks, showEmbedPopup, showInviteSubscribers, showLeaveCollection, showEditCollectionPopup, confirmDelete } = this.state;
    const { mobile } = WindowSize;
    const { account, isLoggedIn } = UserAccount;
    const loading = <FullScreenVerticalCenterContent><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const notFound = <FullScreenVerticalCenterContent><h1>Page Not Found</h1></FullScreenVerticalCenterContent>;
    if (email) return loading;
    const collection = LogicBaseCollectionCache.get(appname);
    if (!collection) return loading;
    if (collection.error) return notFound;
    const users = UserCache.getUsersByIds([collection.owner]);
    if (!users) return loading;
    const user = users[0];
    if (!user || !user._id) return notFound;
    const isOwner = isLoggedIn && collection.owner === account._id;
    const contributors = collection.editors && UserCache.getUsersByIds(collection.editors) || [];
    const isContributor = contributors && contributors.find(({ _id }) => _id === account._id);
    let enableAutoResizing;
    if (Constants.isWidget) {
      enableAutoResizing = Navigation.currentSearch.autoresize !== 'false';
      if (!enableAutoResizing) Connect.setHeightFullscreen();
    }
    return (
      <Scrollable
        style={{
          position: (mobile && !Constants.isWidget) ? 'relative' : 'absolute',
          inset: 0,
          padding: Constants.isWidget && (mobile && '0 1rem' || '1rem 2rem')
            || (mobile && '1rem')
            || '1rem 2rem'
        }}
        rememberScrollPosition={`logicbasecollection`}
        vertical={!scrollDisabled}
        disableFlex={enableAutoResizing && scrollDisabled}
        onScroll={() => {
          PopupService.clearApp();
        }}
      >
        <UserProfile>
          <div>
            <Scrollable
              horizontal
              rememberScrollPosition={`logicbasecollection_users`}
            >
              <div style={{ display: 'flex', gap: '0.75rem', padding: '1rem 0' }}>
                {collection && ([user, ...contributors].map((user) => (
                  <UserProfileHeader
                    key={user && user._id}
                    user={user}
                    medium
                    hideFollow
                    hideProfile
                  />
                )))}
              </div>
            </Scrollable>
            <TitleSection>
              <h1>{collection.title}</h1>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CollectionSubscribeButton collection={collection} />
                <ClickableIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.anchorEl = e.target; this.setState({ menuOpen: true }); }} icon="fas fa-ellipsis-h" />
              </div>
            </TitleSection>
            {collection.description && <p style={{ maxWidth: '40rem' }}>{collection.description}</p>}
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem', margin: '1rem 0' }}>
              <div style={{ cursor: 'pointer' }} onClick={() => Navigation.push(`${Navigation.collections}/${appname}/subscribers`)}><span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{shortenLikesCount(collection.followers || 0)}</span>{collection.followers === 1 ? 'subscriber' : 'subscribers'}</div>
              <div><span style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>{collection.apps.length || 0}</span>{collection.apps.length === 1 ? 'post' : 'posts'}</div>
            </div>
            <div>
              <LogicBaseCollectionRow
                collection={collection}
                wrap
                showCreateNew={isOwner || isContributor}
              />
            </div>
            <EmbedFullscreenButton />
          </div>
        </UserProfile>
        {enableAutoResizing && <ConnectAutoHeight />}
        {!!menuOpen && <Popup
          onClose={() => this.setState({ menuOpen: false })}
          allowBackgroundClose
          anchorEl={this.anchorEl}
        >
          <MenuWrapper>
            <MenuList>
              {(isOwner || isContributor) && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showEditCollectionPopup: true, menuOpen: false }) }}>
                <i className="fas fa-edit"></i>
                <div>Edit Collection</div>
              </MenuItem>}
              <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showShareLinks: true, menuOpen: false }) }}>
                <i className="fas fa-share-alt"></i>
                <div>Share</div>
              </MenuItem>
              <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showEmbedPopup: true, menuOpen: false }) }}>
                <i className="fas fa-code"></i>
                <div>Embed</div>
              </MenuItem>
              {account && account._id === '5cf13c4899397a33fd191007' && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showInviteSubscribers: true, menuOpen: false }) }}>
                <i className="fas fa-users"></i>
                <div>Invite Subscribers</div>
              </MenuItem>}
              {isContributor && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showLeaveCollection: true }) }}>
                <i className="fas fa-sign-out-alt" style={{ fontSize: 17 }}></i>
                <div>Leave Collection</div>
              </MenuItem>}
              {isOwner && <MenuItem onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ confirmDelete: true, menuOpen: false }) }}>
                <i className="fas fa-trash"></i>
                <div>Delete Collection</div>
              </MenuItem>}
            </MenuList>
          </MenuWrapper>
        </Popup>}
        {showShareLinks && <SocialSharePopup tree={collection} onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showShareLinks: false }) }} />}
        {showEmbedPopup && <EmbedCodePopup domainObject={collection} onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showEmbedPopup: false }) }}   />}
        {showInviteSubscribers && <InviteSubscribers collection={collection} onClose={(e) => { if (e) { e.preventDefault(); e.stopPropagation(); } this.setState({ showInviteSubscribers: false }) }} />}
        {showEditCollectionPopup && <LogicBaseCollectionEditPopup
          collection={collection}
          onClose={() => this.setState({ showEditCollectionPopup: false })}
        />}
        <ConfirmDialog allowBackgroundClose text="Are you sure you wish to leave the collection?" description="This cannot be undone." open={!!showLeaveCollection} onNo={() => this.setState({ showLeaveCollection: false })} onYes={() => {
          collection.editors = collection.editors.filter((_id) => _id !== account._id);
          LogicBaseCollectionCache.update(collection);
        }} />
        <ConfirmDialog allowBackgroundClose text="Are you sure you wish to delete?" description="This is permanent and cannot be undone." open={!!confirmDelete} onNo={() => this.setState({ confirmDelete: false })} onYes={this.delete} />
      </Scrollable>
    );
  }
}
